$img-size: 160px;
$img-border: 10px;
$space-above: 100px;
$space-below: $img-size - $space-above + ($img-border*2);

.quote:not(.quote--simple) {
    background: inherit !important;
    color: inherit !important;
}

.quote {
    position: relative;
    padding-top: $space-above;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    z-index: 0;

    .multi-promo--alt & {
        margin: 20px 10px;
        flex-basis: 300px;
        flex-grow: 1;
        flex-shrink: 0;
    }

    .card & {
        background-image: linear-gradient(transparentize($purple-663477, 0.2) 0%, transparentize($purple-663477, 0.2) 100%);
        color: $white !important; // override
        margin: 0;
        padding: 0;
        height: 100%;
    }
}

.quote__caption {
    margin-bottom: 10px;
    position: relative;
    z-index: 1;

    cite {
        font-style: normal;
        font-size: 15px;
    }

    .card & {
        cite {
            font-size: rem(16);
        }
    }
}

.quote__content {
    background: $base-background-color;
    border-radius: 30px / 20px;
    padding: $space-below 20px 20px;
    position: relative;

    .has-color & {
        background: inherit;
    }

    &:after {
        border-radius: 40px / 20px;
        bottom: -10px;
        content: '';
        left: 5px;
        position: absolute;
        right: 5px;
        top: -10px;
        z-index: -1;
    }

    .card & {
        background-color: inherit;
        border-radius: 0;
        padding: 50px;

        &:after {
            display: none;
        }

        @media(max-width: $bp-max-small) {
            padding: 25px;
        }
    }

    .multi-promo__promo & {
        background: linear-gradient(45deg, rgba(108, 29, 119, 1) 0%, rgba(181, 0, 84, 1) 100%);
        border-radius: 40px;
        position: relative;

        &:before {
            background-color: $white;
            border-radius: 30px;
            content: "";
            height: calc(100% - 20px);
            display: block;
            position: absolute;
                left: -1px;
                top: 10px;
            width: calc(100% + 2px);
        }
    }
}

.quote__quote-text {
    font-family: $merriweather;
    font-size: 22px;
    position: relative;
    z-index: 1;

    .card & {
        font-family: $merriweather;
        font-size: rem(28);
        font-weight: 700;
        line-height: 1.375;

        @media(max-width: $bp-max-medium) {
            font-size: 1.6rem;
        }
    }

    @media(max-width: $bp-max-small) {
        font-size: 18px;
    }
}

.quote__img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    img {
        margin: 0 auto;
        // border-radius: 50%; TBF-265
        max-width: $img-size;
    }

    .card & {
        display: none;
    }
}



.quote__quote {
    margin-bottom: 1em;
    color: $grey-353531;
    font-family: $merriweather;
    font-size: 22px;
    font-weight: 300;
    line-height: 34px;
}

.quote__author {
    margin-bottom: 0.5em;
    color: $grey-595956;
    font-family: $stevie;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.quote__link {
    font-size: 15px;

    a {
        // override
        .u-color-pair & {
            color: $blue-314a8f !important;
        }

        &:hover {
            opacity: 0.7;

            &::after {
                transform: translateX(3px);
            }
        }

        &::after {
            font-family: $stevie;
            font-weight: 500;
            content: ' _>';
            display: inline-block;
            margin-left: 5px;
            transition: transform 450ms;
        }
    }

    .card & {
        font-size: rem(16);

        a {
            color: $white;
        }
    }
}

.quote--simple {
}
