.utility-nav {
  .has-submenu {
    margin-left: rem(-10);
    @media(max-width: $bp-max-medium) {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .condensed-header__top-left > & {
    @media(max-width: $bp-max-medium) {
      display: none;
    }
  }
}

.utility-nav__list {
  @include zero-list;
  display: flex;
}

.utility-nav__item {
  position: relative;
  margin-right: rem(30);
  font-size: rem(15);
  color: $grey-353531;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  @media(max-width: $bp-max-medium) {
    padding: rem(10) rem(20);
    margin-bottom: rem(4);
  }

  &:hover {
    color: $gold-a58328;
  }

  span {
    padding: 4px 10px;
    @media(max-width: $bp-max-medium) {
      padding: rem(0) rem(20);
      font-size: rem(14);
      display: inline-block;
    }
  }

  &--highlight {
    border: 2px solid rgba($gold-a58328,.7);
    padding: 3px 8px;
    border-radius: 5px;
    margin-top: -5px;
    @media(max-width: $bp-max-medium) {
      padding: rem(10) rem(20);
      margin-right: 0;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  a {
    color: inherit;
  }

  &.is-open {
    @media(max-width: $bp-max-medium) {
      background-color: #ececec;
      padding-bottom: 0;
    }

    span {
      background-color: #ececec;
      border-top: 5px solid #ececec;
      @media(max-width: $bp-max-medium) {
        border: none;
      }
    }

    .icon {
      transform: rotate(180deg);
    }
  }

  .condensed-header & > a {
    white-space: nowrap;
  }
}
.utility-nav__submenu {
  @include zero-list;
  background-color: #ececec;
  font-family: $stevie;
  color: $grey-595956;
  font-size: rem(14);
  text-transform: none;
  letter-spacing: 0;

  a {
    font-weight: 500;
  }

  &.dropdown__submenu {
    min-width: 226px;
  }
  @media(max-width: $bp-max-medium) {
    width: 100%;
    display: none;
    position: static;
    transition: none;
    height: auto;
    opacity: 1;
  }

  li {
    border-bottom: 1px solid $base-border-color;
    display: block;
    margin: 0;
    padding: rem(10);
    @media(max-width: $bp-max-medium) {
      padding: rem(10) rem(20);
    }

    &:hover {
      background-color: $grey-f4f4f4;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}
