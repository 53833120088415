$width-large: 520px;
$overflow: 200px;
$container-width: 1290px;

.hero {
  margin-bottom: 70px;
  position: relative;
  color: $white;
}

.hero__figure {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;

  .has-gradient & {
    left: calc((100% - #{$width-large} - #{$overflow}) / 2);
  }
  @media(max-width: $bp-max-large) {
    left: 0;
  }

  img,
  video {
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    object-fit: cover;
    height: 100%;
    // height: auto;
    width: 100%;
  }
  @media(min-width: $bp-max-medium) {
    .has-gradient & {
      left: calc((100% - #{$width-large} - #{$overflow}) / 2);
    }
    @media(max-width: $bp-max-large) {
      left: 0;
    }
  }
}
//hacks for IE
@media(max-width: $bp-max-medium) {
  height: 100%;
  @supports (-ms-ime-align:auto) {
    .hero__figure {
      img {
        min-width: 1px;
        min-height: 100px;
        width: auto !important;
        max-width: inherit;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .hero__figure {
    img {
      min-width: 1px;
      min-height: 100px;
      width: 100%;
      height: auto !important;
      max-width: inherit;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.hero__container {
  max-width: $container-width;
  margin: auto;
}

.hero__caption {
  position: relative;
  width: $width-large;
  min-height: 700px;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 0;
  @media(max-width: $bp-max-large) {
    padding: 50px 30px;
    min-height: 500px;
  }
  @media(max-width: $bp-max-medium) {
    width: 100%;
    min-height: 300px;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  > .hero__caption-background {
    position: absolute;
    top: 0;
    right: -$overflow;
    bottom: 0;
    left: 0;

    .cssmask & {
      mask-image: linear-gradient(to right, black 45%, transparent 100%);
    }
    @media(min-width:$bp-max-small-medium) and (max-width: $bp-min-large) {
      right: 100px;
    }
    @media(max-width:$bp-max-small-medium) {
      opacity: 0.8;
    }
  }
}

.hero__title {
  color: inherit;
  font-family: $gibson;
  font-size: 50px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: rem(15);
  @media(max-width: $bp-max-medium) {
    font-size: 2.25rem;
  }
  @media(max-width: $bp-max-small) {
    font-size: 2rem;
  }
}

.hero__description {
  margin-bottom: rem(40);
  font-family: $stevie;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7;
  @media(max-width: $bp-max-medium) {
    font-size: 1rem;
  }
}

.hero__subtitle {
  font-family: $merriweather;
  font-size: 28px;
  font-weight: 400;
  line-height: 42px;
  margin-bottom: rem(30);
  @media(max-width: $bp-max-large) {
    margin-bottom: rem(20);
  }
}

.hero__action {
  color: $blue-314a8f;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: transparent !important;
  }

  @media(max-width: $bp-max-large) {
    margin-bottom: rem(20);
  }
}
