// global-footer.scss
// Define global footer styles here

// base
.global-footer {

	color: white;
    background-color: $grey-353531;
	padding: rem(15) 0 rem(48);

	a {
		color: inherit;
	}

	.container--bleed {
		.container__col {
			@include outer-container();
		}
	}

	.container--100 {
		margin-bottom: 0;
	}

	.site-logo {
		margin-bottom: rem(32);
		clear: both;
	}

	@media(max-width: $bp-max-medium) {
		.site-logo {
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
	}

	.copyright,
	.footer-links {
		display: inline-block;
		font-size: 14px;
		margin-bottom: rem(16);
		text-align: left;
	}


	@media($bp-min-large) {

		.copyright {
			float: left;
		}

		.footer-links {
			float: right;
			text-align: right;
		}
	}

}
