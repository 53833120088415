$promo-media-margin: rem(40);

.promo {
    @include component-whitespace();
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin: 0 auto 2.75rem;

    &.is-left,
    &.is-right {
        align-items: center;

		img {
			max-width: 100%;
		}

		.promo__media {
			max-width: rem(520);
		}

		.container--50-50 & {
			display: block;

			.promo__media {
				margin: 0 0 20px 0;
			}

			.promo__cta {
				float: none;
			}
		}
    }

    &.is-left {
        .promo__cta {
            text-align: left;
        }

        .container--70-30 &,
        .container--30-70 & {
            flex-direction: column;

            .promo__media {
                margin-bottom: 20px;
            }
        }
    }

    &.is-right {
        flex-direction: row-reverse;

        .container--70-30 &,
        .container--30-70 & {
            flex-direction: column;

            .promo__media {
            	margin-left: 0;
                margin-bottom: 20px;
            }
			.promo__cta {
				float: none;
			}
        }
    }

    + .promo {
        border-top: 1px solid rgba(1, 1, 1, 0.15);
        padding-top: 50px;
    }

    @media(max-width: $bp-max-medium) {
        &.is-right,
        &.is-left {
            align-items: flex-start;
			flex-direction: column;
        }

    }

    @media(max-width: $bp-max-small) {
        &.is-right,
        &.is-left {
            .promo__media {
                margin-bottom: 1.125rem;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

.promo__media {
    align-self: flex-start;
    margin-bottom: 1.125rem;

    text-align: center;

    .is-left &,
    .is-right & {
        text-align: left;
    }

	.is-left & {
		margin-right: $promo-media-margin;
		margin-bottom: 0;
	}

	.is-right & {
		margin-left: $promo-media-margin;
		margin-bottom: 0;

	}

    a {
        display: block;
    }

	@media(max-width: $bp-max-medium) {
		&,
		.is-left &,
		.is-right & {
			width: auto;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: $promo-media-margin / 1.5;

			picture, img {
				max-width: none;
				width: 100%;
			}
		}
	}

	@media(max-width: $bp-max-small) {
		&,
		.is-left &,
		.is-right & {
			width: 100%;
		}
	}
}

.promo__content {
    flex: 1;
    align-self: flex-start;
}


.promo__title {
    font-size: 1.5rem;

    .u-color-pair & {
        color: $grey-353531 !important;
    }

    .multi-promo__promo--icon-nav {
      font-size: rem(30);
    }
}

.promo__subtitle {

    font-size: 1.2rem;
    font-weight: bold;
}

.promo__cta {
    font-size: 0.938rem;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0;
    text-align: left;
    text-transform: none;

    .u-color-pair & {
		color: $purple-663477 !important;

    	&:hover {
    		color: $white !important;
    	}
    }

	.is-right & {
		float: right;
	}

	@media(max-width: $bp-max-medium) {
		.is-right & {
			float: none;
		}
	}
}


.promo__description {
    font-size: rem(16);
	margin-bottom: rem(33);

    .u-color-pair & {
        color: $grey-353531 !important;
    }
}

.promo__links-title {
	font-size: 1.125rem;
	margin-bottom: rem(14);

    .u-color-pair & {
        color: $grey-353531 !important;
    }
}

.promo__links {
	@include clearfix;

	ul {
		float: left;
		max-width: 40%;
		margin-left: rem(20);
		padding-left: 0;
	}

	li, a {
		font-size: 1rem;
	}

	a {
		.u-color-pair & {
			color: $blue-314a8f !important;

			&:hover {
				color: rgba($base-link-color, .7) !important;
			}
		}
	}

	ul:last-child:not(:first-child) {
		margin-left: 10%;
	}

	@media(max-width: $bp-max-medium) {
		ul {
			float: none;
			max-width: none;
			margin-bottom: 0;
		}

		ul:last-child {
			margin-left: rem(20);
			margin-bottom: rem(30);
		}
	}
}
