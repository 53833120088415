.newsletter {
  display: flex;
  max-width: 410px;
  padding: 32px;
  color: $grey-f4f4f4;
  border: 1px solid rgba($white,.2);
  border-radius: 10px;

  @media(max-width: $bp-max-small) {
    display: block;
  }
}

.newsletter__icon {
    margin-right:  32px;
    
    img {
        max-width: 70px;
    }

    @media(max-width: $bp-max-small) {
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.newsletter__title {
    margin-bottom: 14px;
    font-family: $gibson;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}

.newsletter__blurb {
    margin-bottom: 28px;
    font-family: $stevie;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.newsletter__button {
    .btn {
        color: $white;
        border: 2px solid rgba($white, 0.5);
        text-transform: none;
        font-size: 15px;
        font-weight: 700;

        &:active,
        &:hover {
          color: $grey-353531;
          background-color: $white;
          border-color: $white;
        }
    }

    @media(max-width: $bp-max-small) {
        .btn {
            margin-bottom: 0;
        }
    }
}
