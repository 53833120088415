$bp-sharebar-small: em(600);

.sharebar {
	align-items: center;
	display: flex;
	justify-content: center;

	.at-resp-share-element .at-share-btn:hover {
		opacity: 0.7;
		transform: none;
	}

	@media(max-width: $bp-sharebar-small) {
		display: none;
	}
}

.sharebarInline {
    align-items: center;
    display: flex;
    justify-content:flex-start;

    .at-resp-share-element .at-share-btn:hover {
        opacity: 0.7;
        transform: none;
    }

    @media(max-width: $bp-sharebar-small) {
        display: none;
    }
}

.sharebar__text {
	color: $grey-a3a3a1;
	font-family: $gibson;
	font-size: 13px;
	font-weight: bold;
	letter-spacing: 1px;
	margin-right: 15px;
}
