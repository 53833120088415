.back-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;

    display: flex;
    padding: 15px;

    background-color: $blue-314a8f;
    border-radius: 50%;
    cursor: pointer;

    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms;

    z-index: 999;

    &.is-visible {
        opacity: 1;
        pointer-events: auto;
    }
}

.back-to-top__icon {
    fill: white;
    width: 20px;
    height: 20px;
}

@media (max-width: $bp-max-medium){
    .back-to-top {
        padding: 10px;
    }

    .back-to-top__icon {
        width: 16px;
        height: 16px;
    }
}
