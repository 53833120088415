.latest-content {
	.search-item {
		border-bottom: 1px solid $grey-d1d1d0;
		flex-direction: row;

		@media(max-width: $bp-max-small) {
			display: block;
		}
	}

	.search-item__eyebrow {
		color: $teal-75b9b9;
		font-size: 15px;
	}

	.search-item__media {
		margin-bottom: 0;
	}

	.search-item__author {
		font-weight: 400;
		text-transform: none;

		a {
			font-weight: 400;
			text-transform: uppercase;
		}
	}

	.search-item__date {
		font-size: 13px;
		font-style: italic;
		font-weight: 400;
		text-transform: none;
	}

	.search-item__cta {
		font-size: 14px;
	}
}
