.promo-icon-link {
    text-align: center;
    margin-bottom: 46px;
}

.promo-icon-link__icon {
    height: 85px;
    margin: 21px 0;

}

.promo-icon-link__link {
    display: block;
    padding: rem(32) rem(10);

    font-family: $gibson;
    font-size: rem(30);
    font-weight: bold;

    border-top: 2px solid rgba(#a3a3a1, 0.5);

    a {
        color: $grey-353531;
        margin-right: 5px;
    }

    &:hover {
        opacity: 0.8;
		&::after {
			transform: translateX(3px);
		}
	}

	&::after {
    font-family: $stevie;
    font-weight: 500;
		content: ' _>';
		display: inline-block;
		transition: transform 450ms;
	}

}
