.gallery__items {
    display: grid;
    justify-content: space-between;
    column-gap: 30px;

    &.gallery__items-1-columns-desktop {
        grid-template-columns: repeat(1, 1fr);
    }

    &.gallery__items-2-columns-desktop {
        grid-template-columns: repeat(2, 1fr);
    }

    &.gallery__items-3-columns-desktop {
        grid-template-columns: repeat(3, 1fr);
    }

    &.gallery__items-4-columns-desktop {
        grid-template-columns: repeat(4, 1fr);
    }

    &.gallery__items-5-columns-desktop {
        grid-template-columns: repeat(5, 1fr);
    }

    &.gallery__items-6-columns-desktop {
        grid-template-columns: repeat(6, 1fr);
    }

    @include media($bp-g-max-medium) {
        &.gallery__items-1-columns-tablet {
            grid-template-columns: repeat(1, 1fr);
        }
    
        &.gallery__items-2-columns-tablet {
            grid-template-columns: repeat(2, 1fr);
        }
    
        &.gallery__items-3-columns-tablet {
            grid-template-columns: repeat(3, 1fr);
        }
    
        &.gallery__items-4-columns-tablet {
            grid-template-columns: repeat(4, 1fr);
        }
    
        &.gallery__items-5-columns-tablet {
            grid-template-columns: repeat(5, 1fr);
        }
    
        &.gallery__items-6-columns-tablet {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    @include media($bp-g-max-small) {
        &.gallery__items-1-columns-mobile {
            grid-template-columns: repeat(1, 1fr);
        }
    
        &.gallery__items-2-columns-mobile {
            grid-template-columns: repeat(2, 1fr);
        }
    
        &.gallery__items-3-columns-mobile {
            grid-template-columns: repeat(3, 1fr);
        }
    
        &.gallery__items-4-columns-mobile {
            grid-template-columns: repeat(4, 1fr);
        }
    
        &.gallery__items-5-columns-mobile {
            grid-template-columns: repeat(5, 1fr);
        }
    
        &.gallery__items-6-columns-mobile {
            grid-template-columns: repeat(6, 1fr);
        }
    }
}