$search-facets__basic-padding: rem(9);
$search-facets__values-indent: rem(16);

.search-facets {
}

.search-facets__group-top,
.search-facets__group-more,
.search-facets__group-heading,
.search-facets__values-wrapper {
    padding: $search-facets__basic-padding;
    padding-bottom: 0;
}

.search-facets__filter-close {
    position: absolute;
    top: rem(-20);
    left: 0;
    display: none;
    height: rem(40);
    width: 100%;

    button {
        padding: rem(8) rem(12);
        font-size: 1.2rem;
        margin: 0 auto;
        display: block;
        text-align: center;
    }

    .icon  {
        color: $white;
        margin-left: rem(6);
        float: right;
    }

    .icon,
    .icon svg {
        width: rem(24);
        height: rem(24);
    }

    @media(max-width: $bp-max-medium) {
        display: block;
    }
}


.search-facets__clear-all {
    text-align: right;
    padding: $search-facets__basic-padding rem(8);

    @media(max-width: $bp-max-medium) {
        float: right;
    }
}

.search-facets__group {
    @include clearfix;
    clear: both;
    margin-bottom: rem(40);
    cursor: pointer;

    border-style: solid;
    border-color: transparent;
    border-width: 1px 1px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;


    &.is-open {
        .search-facets__group-heading {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-color: $base-border-color $base-border-color transparent;
        }

        .search-facets__group-wrapper {
            .is-open.search-facets__group {
                .search-facets__group-heading {
                    border-bottom: 1px solid $base-border-color;
                }

            }
        }
    }

    & .search-facets__group {
        margin-top: rem(20);
        margin-bottom: 0;
    }
}

// Root Group Heading
.search-facets__group-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    border-radius: 5px;
    border-color: $base-border-color;
    padding: $search-facets__basic-padding;

    .search-facets__label {
        margin-bottom: 0;
    }

    button {
        margin-top: -5px;
    }
}

// Child Group headings
.search-facets__group-wrapper {
    overflow: hidden;
    border: 1px solid $base-border-color;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .search-facets__group-heading {
        background-color: transparent;
        padding: rem(32) $search-facets__basic-padding rem(8);
        border: 0;
	}

    .search-facets__group-wrapper {
        border: 0;
    }
}

.search-facets__group-more,
.search-facets__group-toggle,
.search-facets__group-clear,
.search-facets__clear-all,
.search-facets__value-expand {
    background: transparent;
    border: 0;
    cursor: pointer;

    button {
        background: transparent;
        border: none;
        cursor: pointer;

        color: $blue-314a8f;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
}

.search-facets__group-toggle,
.search-facets__value-expand,
.search-facets__values-expand {
    transition: transform 0.2s;

    &.is-open {
        transform: rotate(180deg);
    }
}

.search-facets__value-expand,
.search-facets__group-toggle {
    svg {
        fill: $blue-314a8f;
    }
}

.search-facets__group-top {
    width: 100%;
    text-align: right;
}

.search-facets__group-clear {
    display: inline-block;
}

.search-facets__group-selected {
    border-bottom: 1px solid $base-border-color;
}

.search-facets__group-more {
    text-align: right;
    padding-bottom: $search-facets__basic-padding;
}

.search-facets__value-expand {
    float: right;
}

.search-facets__group-values {
    list-style: none;
    margin: 0;

    &.is-level-1,
    &.is-level-2 {
        margin-left: $search-facets__values-indent;
    }

    li {
        display: flex;

        label {
            flex: auto;
            display: inline-block;
            font-size: rem(14);
            padding-left: rem(8);
        }

        input {
            flex: none;
            margin-top: 3px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}


.search-facets__label {

    margin-bottom: 15px;

    color: $grey-353531;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
}

.search-facets__group-body {
    transition: height 0.2s ease-in;

    .search-facets__group-body {
        padding-left: 0;
    }
}

.search-facets__values-wrapper {
    overflow: hidden;

    // when a group has no direct values, but does have childGroups
    // we want to remove the top-padding from the first childGroup
    &.has-no-values + .search-facets__group {
        .search-facets__group-heading {
            padding-top: 0;
        }
    }

    .search-facets__values-wrapper {
        padding-right: 0;
        padding-left: 0;
    }
}
