.text-banner {
	@include component-whitespace();
	padding: 5.25rem 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	@media(max-width: $bp-max-medium) {
		padding: 3.75rem 0;
	}
}

.text-banner__content {
	max-width: 52.5rem;
	margin-left: auto;
	margin-right: auto;

	@media(max-width: $bp-max-medium) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}


.text-banner__title {
	font-family: $gibson;
	font-size: 2.375em;
	line-height: 1.2;
	text-align: center;
	margin-bottom: 4.1875rem;

	@media(max-width: $bp-max-medium) {
		margin-bottom: 2.1875rem;
	}
}

.text-banner__subtitle {
	font-family: $merriweather;
	font-size: 1.5rem;
	font-weight: 300;
	line-height: 1.5;

	@media(max-width: $bp-max-medium) {
		font-size: 1.2rem;
	}
}
.text-banner__description {
	font-family: $stevie;
	font-size: 1.2rem;
	line-height: 1.67;
	margin-bottom: 0;

	@media(max-width: $bp-max-medium) {
		font-size: 1rem;
	}
}

.text-banner__cta-wrap {
	text-align: center;
}

.text-banner__cta {
	display: inline-block;
	font-family: $gibson;
	font-weight: 600;
	font-size: 1.063rem;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	padding: 12px 24px;
	border: 1px solid $white;
	border-radius: 2rem;
	margin-top: 2.625rem;
    transition: background-color 0.75s cubic-bezier(0.4, 0, 0.2, 1), color 0.75s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In

	&:hover {
		.u-color-pair & {
			color: $blue-314a8f !important;
			background-color: $white !important;
		}
	}

	@media(max-width: $bp-max-medium) {
		margin-top: 2rem;
	}
}

.container--50-50,
.container--70-30,
.container--30-70 {
	.text-banner__content {
		padding: 1.6rem;
	}
}
