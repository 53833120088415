
.news-item-list {
    max-width: 900px;
    margin: 0 auto 24px;
}

.news-item-list__item {
    padding: 30px 0;
    border-top: 1px solid $grey-d1d1d0;

    &:first-child {
        border-top: 0;
    }
}

.news-item {

}

.news-item__date {

    line-height: 1.5;
    margin-bottom: rem(15);
    font-size: rem(13);
    color: $grey-a3a3a1;
    text-transform: uppercase;
    letter-spacing: .07em;
    font-weight: 500;
    color: $grey-a3a3a1;
    font-family: $stevie;
}


.news-item__content {
    color: $grey-595956;
    font-size: rem(18);
    line-height: rem(30);
    font-weight: 500;
    transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1), color 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In

    &:hover {
			color: rgba($grey-595956, .7)
		}
}
