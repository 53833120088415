.condensed-header__top {
  display: flex;
  flex-wrap: wrap;

  li {
    margin-bottom: 0;
  }
  @media(max-width: $bp-max-medium) {
    justify-content: center;

    .primary-nav__menu {
      display: none;
    }
  }
}

.condensed-header__top-left {
  align-items: center;
  display: flex;
  width: 100%;
  padding-right: rem(50);

  .search-box {
    float: right;
    right: 0;
    max-width: rem(500);
  }
  @media(max-width: $bp-max-medium) {

    align-items: flex-start;
    padding-right: 0;
    justify-content: flex-end;
    flex-wrap: wrap;

    .site-logo {
     order: 1;
     text-align: center;
     margin-right: auto;
    }

    .primary-nav {
      order: 3;
      margin-right: 0;

      &.is-open {
        width: 100%;
      }
    }

    .primary-nav__toggle {
      position: absolute;
      padding-left: 0;
      padding-top: 0;
      top: rem(16);
      right: 0;

    }

    .primary-nav__menu {
      clear: both;
      display: block;
    }

    .search-box {
      order: 2;
      position: absolute;
      min-width: 0;
      width: 38px;
      max-width: rem(280);
      top: rem(16) !important; // override inline style, can't find source
      left: rem(0) !important;
      right: rem(46);
      float: none;
      margin-left: auto;
      margin-top: 0;
      background-color: #fff;

      .search-box__input {
        position: relative;
        top: 0;
        right: 0;
        max-width: rem(280);
        transition: none;

        input[type=text] {
          padding-bottom: rem(8);
        }
      }

      &.is-open .search-box__input {
        position: absolute;
        right: 0;
      }
    }

  }
  @media(max-width: $bp-max-small) {
    .search-box {
      .search-box__input {
        max-width: rem(200);
      }
    }
  }
}

.condensed-header__top-right {
  display: flex;
  align-items: center;
  margin-left: auto;
  @media(max-width: $bp-max-medium) {
    display: none;
  }
}
