$pull-quote-fill: $grey-d1d1d0;

.pull-quote {
    @include component-whitespace();
    background-color: rgba($purple-663477,.8);
    padding: rem(40);

    @media(max-width: $bp-max-medium) {
      padding: rem(30);
    }

    &.pull-quote--story-mosaic {
      background-color: transparent;
      padding: 0;
      margin-bottom: 0;
    }
}

// blockquote
.pull-quote__quote {
    @include zero;
    font-size: 1.4rem;
    color: $white;

    p {
    font-size: rem(28);
    font-family: $merriweather;
    font-weight: 700;
    line-height: 1.375;

      @media(max-width: $bp-max-medium) {
        font-size: 1.6rem;
      }
    }
}

.pull-quote__caption {
    cite {
        display: block;
        font-size: rem(16);
        font-style: normal;
        padding-right: 30%;
        text-align: left;
        line-height: 1.6;

        .pull-quote--story-mosaic & {
          padding-right: 0;
        }

        @media(max-width: $bp-max-medium) {
            padding-left: 0%;
        }

        span:first-child {
            font-family: $stevie;
        }

        a {
          color: white;
          cursor: pointer;

          &:hover {
            color: rgba($white, .7);

            &::after {
              transform: translateX(3px);
            }
          }

          &::after {
            content: ' _>';
            display: inline-block;
            transition: transform 450ms;
            margin-left: rem(4);
          }
      }
    }
}
