// video-block.scss
// define video styles here

// base
.video-block{
    @include component-whitespace();

    // story mosaic
    .story-mosaic__content & {
        display: flex;
        height: 100%;
        margin-bottom: 0;
        width: 100%;
    }
}

.video-block__video-wrapper {
    position: relative;

    // story mosaic
    .story-mosaic__content & {
        flex-grow: 1;
    }
}

// title
.video-block__title{
    color: $white;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 25px;
    margin-bottom: 0;
    padding: 10px;
    text-transform: uppercase;

    // story mosaic
    .story-mosaic__content & {
        max-width: 375px;
        text-align: center;
    }


    .story-mosaic__content:hover & {
        opacity: 0.7;
    }
}

// media
.video-block__media{}

// media wrapper
// Intrinsic Ratios - http://alistapart.com/article/creating-intrinsic-ratios-for-video
.video-block__media-wrapper{
    position: relative;
    padding-bottom: 56.25%; // 16x9 Aspect Ratio
    height: 0;
    overflow: hidden;
    max-width: 100%;

    // video, iframe, object and embed
    video,
    iframe,
    object,
    embed{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-bottom: rem(20);
    }
}

// media placeholder
// we position this appropriately but hide it be default.
// only sitecore authors should see this display.
.video-block__media-placeholder{
    display: none;
    width: 100%;
}

// figcaption
.video-block__caption{
	font-size: rem(16);
	line-height: 1.45;
	max-width: 700px;
	margin: 0 auto;
	padding-bottom: em(10);
	padding-top: em(10);
	text-align: center;

	@media(max-width: $bp-max-medium) {
		font-size: rem(18);
	}
}

.video-block__overlay {
    align-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    opacity: 0.85;
    position: absolute;
    width: 100%;
    z-index: 2;

    &:before {
        content: url("/assets/img/svg-sprite/play-video.svg");
        display: block;
        height: 72px;
        text-align: center;
        margin-bottom: 20px;
        width: 72px;

        .story-mosaic__content & {
            opacity: 0.7;
        }
    }

    &:hover {
        cursor: pointer;

        .story-mosaic__content &:before {
            opacity: 1;
        }
    }

    .is-open & {
        display: none;
    }
}

.video-block__overlay-thumbnail {
    background-position: center;
    background-size: cover;
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;

    .is-open & {
        display: none;
    }
}

.video-block__link {
    text-align: center;

    a {
        font-size: 16px;

        &:hover {
            &::after {
                transform: translateX(3px);
            }
        }

        &::after {
            content: ' _>';
            display: inline-block;
            margin-left: 5px;
            transition: transform 450ms;
            font-family: $stevie;
            font-weight: 500;
        }
    }
}


// video modal
.jquery-modal.blocker {
    z-index: 10;
}

.jquery-modal.blocker .modal {
    background: transparent;
    box-shadow: none;
    max-width: 800px;
}

.jquery-modal.blocker .modal .iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.jquery-modal.blocker .modal .iframe-container iframe {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}

// Jquery-modal
.blocker{
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    overflow:auto;
    z-index:1;
    padding:20px;
    box-sizing:border-box;
    background-color:#000;
    background-color:rgba(0,0,0,0.75);
    text-align:center
}
.blocker:before{
    content:"";
    display:inline-block;
    height:100%;
    vertical-align:middle;
    margin-right:-0.05em
}
.blocker.behind{
    background-color:transparent
}
.modal{
    display:none;
    vertical-align:middle;
    position:relative;
    z-index:2;
    max-width:500px;
    box-sizing:border-box;
    width:90%;
    background:#fff;
    padding:15px 30px;
    -webkit-border-radius:8px;
    -moz-border-radius:8px;
    -o-border-radius:8px;
    -ms-border-radius:8px;
    border-radius:8px;
    -webkit-box-shadow:0 0 10px #000;
    -moz-box-shadow:0 0 10px #000;
    -o-box-shadow:0 0 10px #000;
    -ms-box-shadow:0 0 10px #000;
    box-shadow:0 0 10px #000;
    text-align:left
}
.modal a.close-modal{
    position:absolute;
    top:-12.5px;
    right:-12.5px;
    display:block;
    width:30px;
    height:30px;
    text-indent:-9999px;
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center center;
    background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==')
}
.modal-spinner{
    display:none;
    position:fixed;
    top:50%;
    left:50%;
    transform:translateY(-50%) translateX(-50%);
    padding:12px 16px;
    border-radius:5px;
    background-color:#111;
    height:20px
}
.modal-spinner>div{
    border-radius:100px;
    background-color:#fff;
    height:20px;
    width:2px;
    margin:0 1px;
    display:inline-block;
    -webkit-animation:sk-stretchdelay 1.2s infinite ease-in-out;
    animation:sk-stretchdelay 1.2s infinite ease-in-out
}
.modal-spinner .rect2{
    -webkit-animation-delay:-1.1s;
    animation-delay:-1.1s
}
.modal-spinner .rect3{
    -webkit-animation-delay:-1.0s;
    animation-delay:-1.0s
}
.modal-spinner .rect4{
    -webkit-animation-delay:-0.9s;
    animation-delay:-0.9s
}
@-webkit-keyframes sk-stretchdelay{
    0%,40%,100%{
        -webkit-transform:scaleY(0.5)
    }
    20%{
        -webkit-transform:scaleY(1.0)
    }
}
@keyframes sk-stretchdelay{
    0%,40%,100%{
        transform:scaleY(0.5);
        -webkit-transform:scaleY(0.5)
    }
    20%{
        transform:scaleY(1.0);
        -webkit-transform:scaleY(1.0)
    }
}
