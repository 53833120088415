// chart-block.scss

// base
.chart-block {
    @include component-whitespace();
    display: block;
    border-top: 4px solid $grey-d1d1d0;
    border-bottom: 2px solid $grey-d1d1d0;
    padding: 40px;

    @media(max-width: 600px) {
        padding: 40px 0;
    }

    width: 100%;
    max-width: rem(850);
}

.chart-block__title {
    font-family: $gibson;
    font-size: rem(24);
    font-weight: 600;
}

.chart-block__sub-title {
    font-family: $stevie;
    font-size: rem(16);
    font-weight: 500;
    display: block;
}

.chart-block__figure-text {
    font-family: $stevie;
    font-size: rem(13);
    font-weight: 400;
    text-transform: uppercase;
    color: $grey-a3a3a1;
    display: block;
    letter-spacing: 1px;
}

.chart-block__source, .chart-block__notes  {
    font-family: $stevie;
    font-size: rem(14);
    font-weight: 400;
    color: $grey-a3a3a1;
    display: block;
}

.chart-block__notes {
    margin-top: 10px;

    p {
        margin-bottom: 0 !important;
    }
}

.chart-block__table {
    table {
        @media (max-width: $bp-max-medium) {
            width: auto !important;
            height: auto !important;
        }
    }

    td, th {
        border: none;
        text-align: left;

        &.blank {
            background-color: $white;
        }

        &.blue {
            background-color: $blue-314a8f;
            color: $white;
            text-align: center;
        }

        &.rank {
            font-weight: 600;
        }

        &.highlight {
            font-weight: 600;
        }
    }

    thead {
        font-family: $gibson;
        font-size: rem(16);
        font-weight: 600;
        border-bottom: 2px solid $grey-d1d1d0;
        color: $grey-353531;
        text-transform: uppercase;
        letter-spacing: 0.03em;

        @media(max-width: $bp-max-medium) {
            display: none;
        }
    }

    tbody tr {
        border-bottom: 1px solid $grey-d1d1d0;

        @media (max-width: $bp-max-medium) {
            padding: rem(20) 0;

            &, td {
                font-size: rem(16);
                display: block;
                width: 100%;
            }

            td {
                @include clearfix;
                padding-top: rem(10);
                padding-bottom: rem(10);
                padding-right: 0;
                text-align: center;
                // Override any custom widths for the responsive versions
                width: auto !important;
                height: auto !important;

                // Override any custom widths for the responsive versions
                width: inherit !important;
                height: inherit !important;

                &:first-child {
                    padding-top: 0;
                }
            }

            td:last-child {
                padding-bottom: 0;
            }
        }

        @media (max-width: $bp-max-small) {
            td {
                &::before {
                    font-size: rem(14);
                }
            }
        }
    }
}

.chart-block__table table.has-header{

    thead {
        @media(max-width: $bp-max-medium) {
            display: none;
        }
    }

    tbody tr {

        @media (max-width: $bp-max-medium) {
            background-image: linear-gradient(to right, #f4f4f4 0%, #f4f4f4 50%, #ffffff 50%, #ffffff 100%);

            td {
                padding-left: 15px;
                text-align: left;
            }

            td::before {
                content: attr(data-label);
                float: left;
                text-align: right;
                width: 50%;
                height: 100%;
                font-weight: 600;
                padding: 0 20px 0 0;
            }
        }
    }
}

.vega-chart {
    position: relative;

    .custom-hover {
        position: absolute;
        padding: 15px;
        background-color: #fff;
        color: #353531;
        font-family: stevie-sans, sans-serif;
        pointer-events: none;
        max-width: 400px;
        overflow: visible;
        opacity: 0;
        box-shadow: 2px 3.464px 8px 0px rgba(1, 1, 1, 0.15);

        .vega-hover__title {
            font-size: 15px;
            font-weight: 500;
            display: block;
            margin-bottom: 15px;
        }

        .vega-hover__value {
            font-size: 13px;
            font-weight: 800;
            display: block;
        }

        table {
            margin: 0;
        }

        td {
            border: none;
            padding: 5px;
            margin: 0;
            vertical-align: middle;

            &.right-align {
                text-align: right;
            }
        }

        .vega-hover__label {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
        }

        .vega-hover__value {
            display: block;
            font-weight: 800;
            font-size: 14px;
            line-height: 20px;
        }

        .vega-hover__sublabel {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }


        &.show {
            opacity: 1;
        }

        &.tohide {
            opacity: 0;
            top: 0;
            left: 0;
        }
    }
}
