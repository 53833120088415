.report-banner {
	text-align: center;

	.page-banner__title {
		margin-bottom: 10px;

		@media(max-width: $bp-max-medium) {
			font-size: 40px;
			margin-bottom: 5px;
		}
	}

	.page-banner__subtitle {
		font-family: $merriweather;
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 10px;

		@media(max-width: $bp-max-medium) {
			font-size: 20px;
			margin-bottom: 5px;
		}
	}

	.page-banner__description {
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-bottom: 0;

		@media(max-width: $bp-max-medium) {
			font-size: 18px;
		}
	}

	.page-banner__caption {
		@media(max-width: $bp-max-large) {
			padding-right: 30px;
		}

		@media(max-width: $bp-max-small) {
			left: auto;
			padding-bottom: 70px;
			padding-top: 30px;
			position: relative;
			top: auto;
			transform: none;
		}
	}

	.page-banner__figure {
		font-size: 0;

		@media(max-width: $bp-max-small) {
			position:absolute;
			height: 100%;

			img {
				height: 100%;
			}
		}
	}

	.page-banner__caption-inner {
		color: inherit;
	}

	&.has-gradient {
		&:before {
			content: '';
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
		}
	}
}

.report-banner__arrow {
	&:before,
	&:after {
		background-color: $white;
		content: '';
		display: block;
		height: 4px;
		width: 24px;
		position: absolute;
		bottom: 60px;

		@media(max-width: $bp-max-medium) {
			width: 15px;
			bottom: 30px;
		}
	}

	&:after {
		left: calc(50% - 7px);
		transform: translateY(-50%) rotate(45deg);

		@media(max-width: $bp-max-medium) {
			left: calc(50% - 4px);
		}
	}

	&:before {
		left: calc(50% + 7px);
		transform: translateY(-50%) rotate(-45deg);

		@media(max-width: $bp-max-medium) {
			left: calc(50% + 4px);
		}
	}
}