// Slick Slider customization should happen per-component,
// however, there are some global settings that can be set in
// base/_slick-settings.scss

$slideshow-arrow-size: 64;
$slideshow-dots-size: 10;
$slideshow-background-color: #ececec;

.slideshow {
	position: relative;

	// hide all slides except first until
	// slick slider initializes
	.image-block {
		opacity: 0;
		margin-bottom: 0;
	}

	.slick-list {
		position: relative;
		display: block;
		overflow: hidden;
		margin: 0;
		padding: 0;
	}

	.slick-slider {
		.slick-track,
		.slick-list  {
			transform: translate3d(0, 0, 0);
		}
	}

	.slick-slide {
		float: left;
		height: 100%;
		min-height: 1px;
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
	}

	.slick-initialized .image-block {
		opacity: 1;
	}

	.image-block__title {
		display: none;
	}

	.image-block__media {
		margin: 0 15px;
		border-bottom: none;

		figcaption {
			border-bottom: 0;
			color: $grey-595956;
			font-size: 16px;
			font-style: normal;
			max-width: 700px;
			padding-bottom: em(10);
			padding-top: em(10);
			text-align: center;

			@media(max-width: $bp-max-small) {
				padding-left: em(20);
				padding-right: em(20);
			}
		}
	}

	.image-block__link {
		text-align: center;
		font-size: 16px;
	}

	// SlickSlider customizations
	.slick-next,
	.slick-prev {
		border-radius: 0 !important;
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: background-color 0.3s;
		z-index: 100;

		&.slick-disabled {
			svg {
				fill: $grey-a3a3a1;
			}

			&:hover {
				cursor: default;
			}
		}

		@media(max-width: $bp-max-small) {
			background-color: transparent;
		}

		// the button, the icon container
		// and svg need to have dimenions
		// explicitly defined
		&, & .icon, & svg {
			width: rem($slideshow-arrow-size);
			height: rem($slideshow-arrow-size * 2);
		}

		svg {
			fill: $blue-314a8f;
		}

		&:hover {
			cursor: pointer;
		}

		&::before {
			content: "";
		}
	}

	.slick-next {
		right: 0;
	}

	.slick-prev {
		left: 0;
	}

	.slick-dots {
		position: static;

		li {
			margin: 0 8px;

			// match specificy in origin css
			button {
				&::before {
					content: "";
					display: none;
				}
			}

			&:focus {
				// add another box shadow to show
				// rounded outline
				box-shadow: 0 0 0 2px $white,
							0 0 0 4px $base-border-color,
							0 0 0 6px #5B9DD9;
			}
		}

		li, button {
			width: rem($slideshow-dots-size);
			height: rem($slideshow-dots-size);
			border-radius: rem($slideshow-dots-size * 2);
		}

		button {
			width: rem(10);
			height: rem(10);
			margin: 0 auto;
			padding: 4px;
			background-color: $white;
			box-shadow: 0 0 0 2px $white, 0 0 0 4px $base-border-color;
			transition: background-color 0.3s;
		}


		.slick-active {
			button {
				background-color: $base-border-color;
			}
		}
	}

	.container--100 &,
	.container--bleed & {
		padding: 60px 0;
		background-color: $slideshow-background-color;
	}

	@media(max-width: $bp-max-small) {
		.slick-next,
		.slick-prev {
			$size: $slideshow-arrow-size / 2;
			$radius: $size * 2;

			border-radius: rem($radius);

			&, & .icon, & svg {
				width: rem($size);
				height: rem($size * 2);
			}

		}
	}
}

.slideshow__title {
	margin-bottom: 50px;
	text-align: center;

	.container--30-70 &,
	.container--70-30 & {
		margin-bottom: 30px;
	}
}

.slideshow__slider {
	margin-left: auto;
	margin-right: auto;
	max-width: 1290px;
	padding: 0 50px;
	position: relative;

	@media(max-width: $bp-max-small) {
		padding: 0 20px;
	}
}