.site-logo {
	font-size: 1.2rem;
	position: relative;
	z-index: 2;

	@media(max-width: $bp-max-medium) {
		display: table;
	}

	a {
		text-decoration: none;
	}

	.global-header--minimal & {
		padding: 30px 0;
		text-align: center;

		@media(max-width: $bp-max-medium) {
			padding: 20px 0;
			width: 100%;
		}
	}
}

.site-logo__image {
	display: inline-block;
	vertical-align: middle;

	@media(max-width: $bp-max-medium) {
		display: table-row;
		text-align: center;
	}
}

.site-logo__title {
	@media($bp-min-large) {
		padding-left: rem(20);
	}
	@media(max-width: $bp-max-medium) {
		font-size: .95rem;
		text-align: center;
		display: table-row;
		padding-top: rem(10);
	}
}
