.media-object {
    display: flex;
}

.media-object__img {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;

    margin-right: rem(30);
}

.media-object__content {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    margin-top: rem(5);
}
