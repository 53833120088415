// colors.scss
// Define color variables here

$grey-353531: #353531 !default;
$grey-595956: #595956 !default;
$grey-6c6c69: #6c6c69 !default;
$grey-a3a3a1: #a3a3a1 !default;
$grey-d1d1d0: #d1d1d0 !default;
$grey-f4f4f4: #f4f4f4 !default;

$blue-eceef4: #eceef4 !default;
$blue-d9deea: #d9deea !default;
$blue-314a8f: #314a8f !default;
$blue-143577: #143577 !default;
$blue-11193b: #11193b !default;

$gold-f6f3eb: #f6f3eb !default;
$gold-d7c79e: #d7c79e !default;
$gold-a58328: #a58328 !default;

$teal-b7eceb: #b7eceb;
$teal-8ee2e1: #8ee2e1 !default;
$teal-75b9b9: #75b9b9 !default;

$purple-663477: #663477 !default;
$purple-b9a2c1: #b9a2c1 !default;

// base colors

// variables
$white: #fff !default;
$black: #000 !default;

// borders
$base-border-color: $grey-d1d1d0 !default;

// bgs
$base-background-color: $white !default;
