$multi-promo__bp-stack: rem(600);
$multi-promo__promo-space: rem(30);
$bp-max-promo-small: em(630);

.multi-promo,
.multi-promo--wide {
	@include component-whitespace;
	display: flex;
	flex-wrap: wrap;
    width: 100%;
}

.multi-promo--alt {
    display: flex;
    flex-wrap: wrap;

    .multi-promo__promo {
        &:nth-child(2n) {
            padding-right: 0;
        }

        // only one
        &:nth-last-child(1):first-child {
            @media(min-width: $bp-min-large) {
                width: 33.333%;
            }
        }

        @media(min-width: em(740)) {
            width: 50%;
        }

        @media(min-width: $bp-min-large) {
            width: 33.333%;

            &:nth-child(2n) {
            padding-right: 30px;
            }
        }
    }

    @media(min-width: $bp-min-medium) {
        .container--70-30 &,
        .container--30-70 & {
            .multi-promo__promo {
                &:nth-last-child(3):first-child,
                &:nth-last-child(3):first-child ~ .multi-promo__promo:nth-child(2){
                    width: 50%;
                }
            }
        }
    }

    @media(min-width: $bp-min-large) {
        .multi-promo__promo:nth-last-child(-n+2):first-child {
            margin-left: auto;
        }
        .multi-promo__promo:nth-last-child(-n+2):first-child ~ .multi-promo__promo {
            margin-right: auto;
        }

        .multi-promo__promo:nth-last-child(1):first-child {
            margin: auto;
        }

        .container--70-30 &,
        .container--30-70 & {
            .multi-promo__promo {
                width: 50%;

                &:nth-child(3):last-child {
                    width: 50%;
                }

                // only one
                &:nth-last-child(1):first-child {
                    width: 50%;
                }
            }
        }
    }
}

.multi-promo__promo {
	width: 100%;
	padding-right: $multi-promo__promo-space;

	.multi-promo--bordered & {
		// 2
		&:nth-last-child(n+2) {
			.promo {
				border-right: 1px solid $grey-d1d1d0;
				padding-right: 50px;
			}

			&:last-child {
				.promo {
					border-right: 0;
					padding-right: 0;
				}
			}

			@media(max-width: $bp-max-promo-small) {
				border-bottom: 1px solid $grey-d1d1d0;
				margin-bottom: 30px;
				width: 100%;

				.promo {
					padding-right: 0;
					border-right: 0;
				}
			}
		}

		// 3
		&:nth-last-child(n+3),
		&:nth-last-child(n+3) ~ .multi-promo__promo {
			.promo {
				border-right: 1px solid $grey-d1d1d0;
				padding-right: 30px;

				img {
					height: 100px;
					width: 100px;
				}
			}

			.promo__media {
				margin-right: 25px;
			}

			&:last-child {
				.promo {
					border-right: 0;
					padding-right: 0;
				}
			}

			@media(max-width: $bp-max-medium) {
				border-bottom: 1px solid $grey-d1d1d0;
				margin-bottom: 30px;

				&:nth-child(2n) {
					.promo {
						border-right: 0;
						padding-right: 0;
					}
				}

    			&:last-child {
    				border-bottom: 0;
    			}
    		}

			@media(max-width: $bp-max-promo-small) {
				width:100%;

				.promo {
					padding-right: 0;
					border-right: 0;
				}
			}
		}

		// 4 or more
		&:nth-last-child(n+4),
		&:nth-last-child(n+4) ~ .multi-promo__promo {
			border-bottom: 1px solid $grey-d1d1d0;
			margin-bottom: 30px;

			&:nth-child(2n) {
				.promo {
					border-right: 0;
					padding-right: 0;
				}
			}


			@media(max-width: $bp-max-medium) {
				&:nth-child(3) {
					border-bottom: 0;
					margin-bottom: 0;
				}
			}

			@media(max-width: $bp-max-promo-small) {
				width: 100%;

				&:nth-child(3) {
					border-bottom: 1px solid $grey-d1d1d0;
					margin-bottom: 30px;
				}
			}
		}

    // suppress bottom border/space on all odd elements that are the last child
    &:nth-child(odd):nth-last-child(1) {
      border-bottom: 0 !important;
      margin-bottom: 0 !important;
    }

    // suppress bottom border/space on all direct siblings of odd elements that
    // are the second-to-last child
    &:nth-child(odd):nth-last-child(2),
    &:nth-child(odd):nth-last-child(2) + .multi-promo__promo {
      border-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
	}


	.card & {
		width: 100%;
	}

	@media(min-width: $bp-min-large) {
		width: 50%;

		@include exactly(1) {
			width: 100%;
		}

		@include exactly(3) {
			width: 33.333%;
		}
	}

	@media(min-width: em(740)) {
		width: 50%;
	}

	@media(max-width: $bp-min-medium) {
		padding-right: 0;
	}

	@media(width: $multi-image__bp-stack) {
		width: 100%;
	}


	&--icon-nav {

	}

	.multi-promo--wide & {
		@include exactly(4) {
			width: 25%;

			@media(max-width: $bp-max-large) {
				width: 50%;
			}

			@media(max-width: $bp-max-medium) {
				width: 100%;
			}
		}
	}
}

// 70-column
.container--70-30 .container__col:first-child,
.container--30-70 .container__col:last-child {

	.multi-promo__promo {
		@media(width: $bp-max-large) {
			width: 100%;
		}
	}

	.multi-promo--wide .multi-promo__promo {
		@include exactly(4) {
			width: 50%;

			@media(max-width: $multi-image__bp-stack) {
				width: 100%;
			}
		}
	}
}

// 30-column and 50-column
.container--70-30 .container__col:last-child,
.container--30-70 .container__col:first-child,
.container--50-50 .container__col {
	.multi-promo__promo {
		width: 100%;
	}
}

.multi-promo__title {
	text-align: center;
	margin-bottom: 40px;
    width: 100%;
}
