.feature-banner {
	margin-bottom: 30px;
	position: relative;
}

.feature-banner__eyebrow {
	font-size: 17px;
	font-weight: 600;
	text-transform: uppercase;
	color: $gold-a58328; // override color pairing
}

.feature-banner__title {
	color: inherit;
	font-size: 50px;
	line-height: 56px;
	max-width: 450px;

    @media(max-width: $bp-max-medium) {
        font-size: 2.25rem;
        line-height: 2.3rem;
    }

    @media(max-width: $bp-max-small) {
        font-size: 2rem;
        line-height: 2.1rem;
    }
}

.feature-banner__subtitle {
	font-size: 24px;
	font-family: $merriweather;
	line-height: 36px;
	max-width: 450px;
}

.feature-banner__cta {
	background-color: $white;
	border-radius: 5px;
	border: 1px solid $white;
	display: inline-block;
	font-family: $gibson;
	font-size: 1.063rem;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 1;
	padding: 1rem;
	text-transform: uppercase;
	transition: opacity 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In

	&:hover {
		opacity: .7;
	}
}
