$container-bottom-space: rem(60);
$container-top-space: rem(30);
$container-bleed-max: rem(2580);
$container-outer-gutter: rem(30);

.container {
	position: relative;

	@media(min-width: $bp-min-large) {
		margin-bottom: $container-bottom-space;
	}

	@media(max-width: $bp-max-medium) {
		margin-bottom: $container-bottom-space / 2;
	}

	&.is-adjacent {
		margin-bottom: 0;
	}

	&.has-top {
		margin-top: $container-top-space;
	}

	&.hide-at-small {
		@media(max-width: $bp-max-small-medium) {
			display: none;
			visibility: hidden;
		}
	}
}

.container--bleed {
	max-width: $container-bleed-max;
	margin-left: auto;
	margin-right: auto;
	background-repeat: no-repeat;
	background-position: center;

	&>.container__col {
		position: relative;

		// @media(max-width: $bp-max-large) {
		// 	margin-left: $container-outer-gutter;
		// 	margin-right: $container-outer-gutter;
		// }

		.container:last-child {
			margin-bottom: 0;
		}
	}

	&.has-image,
	&.has-color {
		background-size: cover;
		padding-top: $container-bottom-space;
		padding-bottom: $container-bottom-space;

		.container {
			background-color: inherit;
		}

		&>.container__col {
			background-color: inherit;
		}
	}
}

.container--100,
.container--70-30,
.container--50-50,
.container--30-70,
.container--25-25-25-25 {
	@include outer-container();
	@media(max-width: $bp-max-large) {
		margin-left: $container-outer-gutter;
		margin-right: $container-outer-gutter;
	}
}

.container--70-30 {
	.container__col:first-child {
		@include span-columns(8);
	}

	.container__col:last-child {
		@include span-columns(4);
	}

	@include media($bp-g-max-medium) {
		.container__col {
			margin-bottom: $container-bottom-space / 2;
		}

		.container__col:first-child {
			@include span-columns(8); //These 3 col layouts were not breaking soon enough
		}

		.container__col:last-child {
			@include span-columns(8); //These 3 col layouts were not breaking soon enough
			margin-bottom: 0;
		}
	}

	@include media($bp-g-max-small) {

		.container__col:first-child {
			@include span-columns(4);
		}

		.container__col:last-child {
			@include span-columns(4);
		}
	}
}

.container--50-50 {
	.container__col {
		@include span-columns(6);
	}

	@include media($bp-g-max-medium) {

		.container__col {
			@include span-columns(8);
			margin-bottom: 0;
		}

		.container__col:last-child {
			margin-bottom: 0;
		}
	}

	@include media($bp-g-max-small) {

		.container__col {
			@include span-columns(4);
		}
	}
}

.container--25-25-25-25 {
	.container__col {
		@include span-columns(3);
	}

	@include media($bp-g-max-medium) {

		.container__col {
			@include span-columns(4);
			margin-bottom: 0;
		}

		.container__col:nth-of-type(2),
		.container__col:first-of-type {
			margin-bottom: $container-bottom-space / 2;
		}

		.container__col:nth-of-type(2n+0) {
			margin-right: 0;
		}

		.container__col:last-child {
			margin-bottom: 0;
		}
	}

	@include media($bp-g-max-small) {

		.container__col {
			@include span-columns(4);
		}
	}
}

.container--30-70 {
	.container__col:first-child {
		@include span-columns(4);
	}

	.container__col:last-child {
		@include span-columns(8);
	}

	@include media($bp-g-max-medium) {
		.container__col {
			margin-bottom: $container-bottom-space / 2;
		}
		.container__col:first-child {
			@include span-columns(8); //These 3 col layouts were not breaking soon enough
		}

		.container__col:last-child {
			@include span-columns(8); //These 3 col layouts were not breaking soon enough
			margin-bottom: 0;
		}
	}

	@include media($bp-g-max-small) {
		.container__col:first-child {
			@include span-columns(4);
		}

		.container__col:last-child {
			@include span-columns(4);
		}
	}
}

/* Colors */
.container--connect {
	margin-bottom: rem(50);
}

.container--backgroundgrey {
	background-color: $grey-f4f4f4;
	padding-top: $container-bottom-space;
	padding-bottom: $container-bottom-space;
	margin-bottom: 0;
}


.container__inner {
	background-color: inherit;
}
