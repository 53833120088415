$height: 38px;
$icon-size: 24px;
$icon-padding: (($height - $icon-size) / 2);
$width-duration: 500ms;

.search-box {
  position: absolute;
  width: 100%;
  min-width: $height;
  min-height: $height;
  @media(max-width: $bp-max-medium) {
    width: auto;
  }
}

.search-box__input {
  position: absolute;
  top: 0;
  right: 0;
  width: $height;
  max-width: calc(100vw - 100px);
  z-index: 10;
  transition: width $width-duration;

  .is-always-open &,
  .search-box.is-open & {
    width: 100%;
    @media(max-width: $bp-max-medium) {
      width: 500px;
    }
  }

  svg {
    fill: $grey-595956;
    position: absolute;
    left: 0;
    top: 0;
    width: $icon-size;
    height: $icon-size;
    padding: $icon-padding;
    box-sizing: content-box;
    cursor: pointer;

    &:hover,
    .is-always-open &,
    .search-box.is-open & {
      fill: $gold-a58328;
    }
  }


  input {
    position: relative;
    width: 100%;
    padding: 10px 0 10px ($icon-size + $icon-padding);
    box-shadow: none;
    font-size: rem(16);
    opacity: 0;
    border: 1px solid $base-border-color;
    transition: opacity $width-duration/4 $width-duration/2, padding $width-duration;

    .is-always-open &,
    .search-box.is-open & {
      padding: 10px ($icon-size + $icon-padding * 2);
      opacity: 1;
      transition: opacity 100ms, padding $width-duration;
    }
  }
}



.search-box__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  padding: 11px;
  box-sizing: content-box;
  cursor: pointer;
  z-index: 5; // below __input
  opacity: 0;
  transition: opacity 200ms, z-index $width-duration;

  &:hover {
    fill: $gold-a58328;
  }

  .search-box.is-open & {
    z-index: 15; // above __input
    opacity: 1;
    transition: opacity 200ms $width-duration/4, z-index 1ms;
  }
}



.search-box__loader {
  .search-results__spinner {
    position: absolute;
    top: 0;
    right: rem(45);
    left: auto;
    text-align: left;

    img {
      margin-top: rem(4);
      width: rem(32);
      height: rem(32);
    }
  }
}
