.report-nav {
	// visibility: none;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	padding: 20px 20px 20px 0;
	position: absolute;
	z-index: 10;
	transition: background-color 0.2s ease;

	&:hover {
		background-color: rgba(255,255,255,0.7);
	}

	@media(max-width: $bp-max-medium) {
		display: none;
	}
}

.report-nav__list-item-name {
	opacity: 0;
	transition: opacity 0.2s ease;
}

.report-nav__list-item {
	font-size: 15px;
	position: relative;

	&.is-selected {
		&:before {
			background-color: $blue-314a8f;
			height: 14px;
			opacity: 1;
			width: 14px;
		}
	}

	&:before {
		background-color: $white;
		border-radius: 50%;
		border: 2px solid $blue-314a8f;
		box-shadow: 0 0 0 2px $white;
		content: '';
		display: inline-block;
		height: 10px;
		left: -15px;
		opacity: 0.75;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 10px;

		transition: height 0.3s ease, width 0.3s ease;
	}

	&:hover {
		cursor: pointer;

		.report-nav__list-item-name {
			opacity: 1;
		}

		&:before {
			background-color: $blue-314a8f;
			height: 14px;
			opacity: 1;
			width: 14px;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.report-nav__list {
	list-style: none;
	margin-bottom: 0;
}