.event-info {
	background-color: $grey-f4f4f4;

	.addtocalendar {
		.atcb-item {
			padding: 5px 15px;
		}

		.atcb-list {
			box-shadow: 0 0 5px $grey-a3a3a1;
		}
	}
}

.event-info__column-container {
	display: flex;
	margin: auto;
	max-width: rem(850);
	text-align: center;
	padding: 50px 50px 70px 50px;

	.container--50-50 & {
		flex-wrap: wrap;
	}

	@media(max-width: $bp-max-large) {
		.container--70-30 & {
			flex-wrap: wrap;
			padding: 30px 30px 50px 30px;
		}

		.container--50-50 & {
			padding: 30px 30px 50px 30px;
		}
	}

	@media(max-width: $bp-max-medium) {
		.container--100 &,
		.container--bleed & {
			flex-wrap: wrap;
			padding: 30px 30px 50px 30px;
		}
	}
}

.event-info__column {
	flex-basis: 50%;
	flex-shrink: 0;
	padding: 0 20px;

	&:first-child {
		border-right: 1px solid $grey-d1d1d0;
		margin-right: 30px;
	}

	.container--50-50 & {
		border: none;
		flex-basis: 100%;
		margin-right: 0;
		padding: 0;

		&:first-child {
			margin-bottom: 30px;
		}
	}

	@media(max-width: $bp-max-large) {
		.container--70-30 & {
			border: none;
			flex-basis: 100%;
			margin-right: 0;
			padding: 0;

			&:first-child {
				margin-bottom: 30px;
			}
		}
	}

	@media(max-width: $bp-max-medium) {
		.container--100 &,
		.container--bleed & {
			border: none;
			flex-basis: 100%;
			margin-right: 0;
			padding: 0;

			&:first-child {
				margin-bottom: 30px;
			}
		}
	}
}

.event-info__title {
	color: $grey-a3a3a1;
	font-family: $gibson;
	font-size: 13px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.event-info__date {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 0;
}

.event-info__time {
	font-size: 16px;
}

.event-info__link {
	font-size: 16px;

	a {
		vertical-align: middle;
	}

	&:hover {
		&::after {
			transform: translateX(3px);
		}
	}

	&::after {
		color: $blue-314a8f;
		font-family: $stevie;
		font-weight: 500;
		content: ' _>';
		display: inline-block;
		transition: transform 450ms;
		vertical-align: middle;
	}
}

.event-info__icon {
	margin-right: 5px;
	max-width: 20px;
	vertical-align: text-bottom;
}

.event-info__button-container {
	position: relative;
	text-align: center;
	top: -25px;
}

.event-info__button {
	background-color: $blue-314a8f;
	border-radius: 5px;
	color: $white;
	display: inline-block;
	font-family: $gibson;
	font-size: 17px;
	letter-spacing: 1px;
	padding: 15px 20px;
	text-transform: uppercase;

	&:hover {
		color: $white;
		background-color: lighten($blue-314a8f, 5%);
	}
}

.event-info__date-time {
	display: flex;
	justify-content: center;

	@media(max-width: $bp-max-small) {
		display: block;
	}
}

.event-info__date-divider {
	align-self: center;
	display: inline-block;
	margin: 0 15px;
}
