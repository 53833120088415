$list-item-media-margin: rem(30);

.list-item {
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	&.is-left,
	&.is-right {
		align-items: flex-start;
	}

	&.is-left {
		flex-direction: row;

		.list-item__media {
			margin-right: $list-item-media-margin;
			margin-bottom: 0;

			img {
				// border-radius: 50%; TBF-265
				width: 160px;
				height: 160px;
			}
		}
	}
	&.is-right {
		flex-direction: row-reverse;

		.list-item__media {
			margin-left: $list-item-media-margin;
			margin-bottom: 0;
		}
	}


	@media(max-width: $bp-max-large) {
		&.is-right,
		&.is-left {
			flex-direction: column;

			.list-item__media {
				margin-bottom: $list-item-media-margin;
				margin-left: 0;
				margin-right: $list-item-media-margin;
			}
		}
	}

	@media(max-width: $bp-max-medium) {
		&.is-right,
		&.is-left {
			flex-direction: row;
	  }
  }

	@media(max-width: 650px) {
		&.is-right,
		&.is-left {
			flex-direction: column;
		}
	}

	&.list-item--story-mosaic {
		flex-direction: row;

		.list-item__media {
			margin-right: $list-item-media-margin;
			margin-bottom: $list-item-media-margin;

			img {
				// border-radius: 50%; TBF-265
				width: 160px;
				height: 160px;
			}
		}

		@media(max-width: $bp-max-large) {
				flex-direction: column;
		}

		@media(max-width: $bp-max-medium) {
				flex-direction: row;
		}

		@media(max-width: 650px) {
				flex-direction: column;
		}
	}
}



.list-item__media {
	margin-bottom: $list-item-media-margin;
}

.list-item__content {
	flex: 1;
	margin-top: 0;

	@media(max-width: $bp-max-medium) {
			margin-top: 0;
	}

}

.list-item__eyebrow {
	font-family: $gibson;
	font-weight: 600;
	color: $gold-a58328;
	font-size: rem(14.4);
	text-transform: uppercase;
	margin-bottom: rem(2);
	letter-spacing: .07em;
}

.list-item__title {
	font-size: rem(24);
	margin-bottom: rem(8);

	a {
		color: $grey-353531;

		&:hover {
			color: rgba($grey-353531, .7)
		}
	}
}

.list-item__description {
	max-width: 800px;
	font-size: rem(16);
	margin-bottom: rem(15);
}

.list-item__meta {
	@include clearfix;
	line-height: 1.5;
	margin-bottom: rem(15);
	font-size: rem(13);
	color: $grey-a3a3a1;
	text-transform: uppercase;
	letter-spacing: .07em;
	font-weight: 500;

	*:empty {
	  display: none;
    margin: 0;
    padding: 0;
    border: 0;
  }
}

.list-item__date,
.list-item__time {
	  display: block;
}

.list-item__byline {
	display: block;
}

.list-item__cta {
	display: block;
	text-align: left;
}
