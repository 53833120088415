.svg-chevron-down {
    width: 16px;
    height: 9px;
}

.svg-close {
    width: 24px;
    height: 24px;
}

.svg-play-video {
    width: 38px;
    height: 38px;
}
