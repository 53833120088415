.footer-links {
	ul {
		@include zero-list();
		text-align: right;

		@media(max-width: $bp-max-medium) {
			text-align: center;
		}
	}

	li {
		display: inline;
		margin-left: rem(5);

		a:hover {
			color: rgba($white, 0.7);
		}
	}
}