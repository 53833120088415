.primary-nav {
  width: 100%;
  background-color: $white;

  .condensed-header__top-left & {
    margin-left: rem(16);
  }



  .utility-nav__list {
    @include zero-list;
    display: none;
    border-top: 1px solid $base-border-color;
    padding: rem(20) 0;
    margin-top: rem(20);
    @media(max-width: $bp-max-medium) {
      display: block;
    }
  }

  .utility-nav__item {
    display: block;
    // font-size: 0.875rem;
    a {
      padding-top: rem(6);
      padding-bottom: rem(15);
      font-size: 1rem;

      &:hover {
        color: $gold-a58328;
      }
    }

    &--highlight a:hover,
    &--highlight:hover {
      color: rgba(49, 74, 143, 0.7);
    }
  }
}



.primary-nav__toggle {
  display: none;
  padding: rem(16) 0;
  cursor: pointer;
  @media(max-width: $bp-max-medium) {
    display: block;
  }
}

.primary-nav__menu-wrap {
  display: flex;
  flex-wrap: wrap;

  .primary-nav__menu--audience {
    margin-left: auto;
    margin-right: -10px;
  }
  @media(max-width: $bp-max-medium) {
    // stack the nav on smaller screens
    display: block;
  }
}

.primary-nav__menu {
  @include zero-list;
  display: flex;
  // align-items: flex-start;
  // flex-wrap: wrap;
  font-family: $gibson;
  font-weight: 600;
  color: $grey-353531;
  margin-left: -30px;
  margin-top: 20px;
  @media(max-width: $bp-max-medium) {
    flex-direction: column;
  }
  @media(max-width: $bp-max-large) {
    margin-left: 0;
    margin-top: 0;
  }
}



.primary-nav__item {
  position: relative;
  transition: background-color 0.36s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In
  @include zero;
  font-size: 1.125rem;
  line-height: 1.2;
  margin: rem(0) rem(10);
  // responsive layout
  @media(max-width: 510px) {
    display: block;
  }
  @media(max-width: $bp-max-medium) {
    margin: rem(0);
  }

  a,
  span {
    display: inline-block;
    padding: rem(13) rem(20) rem(28);
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    width: 100%;
    font-size: rem(24);
    transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In
    @media(max-width: $bp-max-medium) {
      padding: rem(10) rem(20);
    }

    .primary-nav__menu--audience & {
      padding: rem(21) rem(10) rem(28);
      color: $gold-a58328;
      text-transform: uppercase;
      font-size: rem(15);
      letter-spacing: 0.07em;

      &:hover {
        color: rgba($gold-a58328,.7);
      }
      @media(max-width: $bp-max-medium) {
        padding: rem(10) rem(20);
      }
    }
  }

  a {
    color: $grey-353531;
  }

  &.is-open {
    background-color: #ececec;

    .primary-nav__submenu {
      height: auto;
      opacity: 1;
    }

    .icon {
      transform: rotate(180deg);
    }
  }

  .icon {
    display: none;
    width: rem(20);
    height: rem(20);
    margin-left: rem(8);
    vertical-align: middle;
    transition: transform 0.28s;
  }
  @media(max-width: $bp-max-medium) {
    width: 100%;

    .icon {
      display: inline-block;
      float: right;
      margin-right: rem(-4);
    }
  }
}

.primary-nav__item:not(.has-submenu):hover {
  background-color: #ececec;
}

.primary-nav__submenu {
  @include zero-list;
  position: absolute;
  display: block;
  background-color: #ececec;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.4s ease-out;
  z-index: 100;
  min-width: 250px;

  li {
    border-bottom: 1px solid $base-border-color;
    display: block;
    margin: 0;
    padding: 0 rem(10);

    .primary-nav__menu--audience & {
      @media(max-width: $bp-max-medium) {
        padding-left: rem(15);
      }
    }

    &:hover {
      background-color: $grey-f4f4f4;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
  @media(max-width: $bp-max-medium) {
    width: 100%;
    display: none;
    position: static;
    transition: none;
    height: auto;
    opacity: 1;
  }
}

.primary-nav__subitem {
  display: block;
  transition: background-color 0.36s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In

  &:hover {
    background-color: $base-border-color;
  }

  a {
    color: $grey-353531;
    font-size: rem(18);
    padding: rem(13) rem(10);

    .primary-nav__menu--audience & {
      font-family: $stevie;
      font-weight: 500;
      color: $grey-595956;
      font-size: rem(14);
      text-transform: none;
      letter-spacing: 0;
      padding: rem(10) rem(5);

      &:hover {
        color: $grey-595956;
      }
    }
  }
  @media(max-width: $bp-max-medium) {
    width: 100%;
  }
}
// the last item of each submenu should be a duplicate
// of the root item. We only show it on the mobile nav,
// as the root item is not navigable.
// hide the primary nav last item unless in mobile nav

.primary-nav__subitem:last-child {
  display: none;
  @media(max-width: $bp-max-medium) {
    display: block;
  }
}

.primary-nav .utility-nav__item a {
  @media(max-width: $bp-max-medium) {
    font-size: rem(14);
  }
}

.primary-nav__toggle {
  border: 0;
  background: 0;
  padding-left: rem(20);
}
.primary-nav__label-close,
.primary-nav__label-open {
  font-size: 0.75rem;
  text-transform: uppercase;
  padding-top: rem(4);
  color: $grey-353531;
  font-family: $gibson;
  font-weight: 600;
}

.primary-nav__label-open {
  display: none;
  @media(max-width: $bp-max-medium) {
    display: block;
  }
}

.primary-nav.is-open .primary-nav__label-open {
  display: none;
}

.primary-nav__label-close {
  display: none;
}

.primary-nav.is-open .primary-nav__label-close {
  @media(max-width: $bp-max-medium) {
    display: block;
  }
}
// Icon style and animation code cribbed from
// http://codepen.io/pedrocampos/pen/gufrx/

.primary-nav__toggle-icon {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: rem(30);
  height: rem(30);

  span {
    margin: 0 auto;
    position: relative;
    top: 12px;
    transition-duration: 0s;
    transition-delay: 0.2s;

    &::after,
    &::before {
      position: absolute;
      content: '';
      transition-property: margin, transform;
      transition-duration: 0.2s;
      transition-delay: 0.2s, 0s;
    }

    &,
    &::after,
    &::before {
      display: block;
      width: rem(30);
      height: rem(6);
      background-color: $grey-353531;
    }

    &::before {
      margin-top: -12px;
    }

    &::after {
      margin-top: 12px;
    }
  }
}

.primary-nav.is-open .primary-nav__toggle-icon {
  span {
    background-color: rgba(0, 0, 0, 0.0);
    transition-delay: 0.2s;

    &::after,
    &::before {
      margin-top: 0;
      transition-duration: 0.2s;
      transition-delay: 0.2s, 0s;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.primary-nav__menu--audience .primary-nav__item:last-child,
.primary-nav__menu--audience .primary-nav__item:last-child span {
  margin-right: 0;
}

.primary-nav__menu--audience {
  @media(max-width: $bp-max-medium) {
    margin-top: rem(15);
  }
}
