.related-content {
	// story mosaic
	.story-mosaic__content & {
		padding: 50px;

		@media(max-width: $bp-max-small) {
			padding: 30px;
		}
	}
}

.related-content__title {
	margin-bottom: 50px;
	text-align: center;

	.story-mosaic__content & {
		display: none;
	}
}

.related-content__media {
	display:inline-block;
	margin-right: 30px;
	vertical-align: top;

	@media(max-width: $bp-max-small-medium) {
		.story-mosaic__content & {
			display: block;
			margin-bottom: rem(32);
			text-align: center;
		}
	}
}

.related-content__items {
	@include clearfix;
	font-size: 0; // remove inline-block spacing
	margin-bottom: 50px;

	.story-mosaic__content & {
		margin-bottom: 10px;
	}
}

.related-content__item {
	// only 2 items
	&:nth-last-child(2):first-child {
		width: 50%;
	}
	&:nth-last-child(2):first-child ~ .related-content__item {
		width: calc(50% - 50px);
	}
	&:nth-last-child(2):first-child,
	&:nth-last-child(2):first-child ~ .related-content__item {
		border-right: 1px solid $grey-d1d1d0;
		display: inline-block;
		margin-right: 50px;
		padding-right: 50px;
		vertical-align: top;

		&:last-child {
			margin-right: 0;
			padding-right: 0;
			border-right: 0;
		}

		.container--30-70 &,
		.container--70-30 &,
		.container--50-50 & {
			border-bottom: 1px solid $grey-d1d1d0;
			border-right: 0;
			display: block;
			margin-bottom: 50px;
			padding-bottom: 50px;
			padding-right: 0;
			width: 100%;

			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
			}
		}

		.search-item__description {
			display: block;
		}

		.story-mosaic__content & {
			display: none;

			&:first-child {
				display: block;
			}
		}

		@media(max-width: $bp-max-medium) {
			border-bottom: 1px solid $grey-d1d1d0;
			border-right: 0;
			display: block;
			margin-bottom: 50px;
			padding-bottom: 50px;
			padding-right: 0;
			width: 100%;

			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
			}
		}

		@media(max-width: $bp-max-small) {
			.related-content__media {
				display: block;
				margin-right: 0;
				margin-bottom: 30px;
			}

			.related-content__content {
				width: 100%;
			}

			.search-item__eyebrow {
				margin-bottom: 10px;
			}
		}
	}

	// 4 items
	&:nth-last-child(4):first-child {
		border-right: 1px solid $grey-d1d1d0;
		display: inline-block;
		float: left;
		margin-right: 50px;
		padding-right: 50px;
		vertical-align: top;
		width: calc(50% - 50px);

		.container--30-70 &,
		.container--70-30 &,
		.container--50-50 & {
			border-bottom: 1px solid $grey-d1d1d0;
			border-right: 0;
			margin-bottom: 30px;
			margin-right: 0;
			padding-bottom: 30px;
			padding-right: 0;
			width: 100%;
		}

		@media(max-width: $bp-max-medium) {
			border-bottom: 1px solid $grey-d1d1d0;
			border-right: 0;
			margin-bottom: 30px;
			margin-right: 0;
			padding-bottom: 30px;
			padding-right: 0;
			width: 100%;
		}

		@media(max-width: $bp-max-small) {
			.related-content__media {
				display: block;
				margin-right: 0;
				margin-bottom: 30px;
			}

			.related-content__content {
				width: 100%;
			}

			.search-item__eyebrow {
				margin-bottom: 10px;
			}
		}
	}

	&:nth-last-child(4):first-child ~ .related-content__item {
		border-bottom: 1px solid $grey-d1d1d0;
		clear: right;
		float: right;
		margin-bottom: 20px;
		padding-bottom: 20px;
		width: 50%;

		.related-content__media,
		.search-item__eyebrow,
		.search-item__description,
		.search-item__meta,
		.related-content__cta {
			display: none;
		}

		.search-item__title {
			margin-bottom: 0;

			a {
				font-family: $stevie;
				color: $blue-314a8f;
				font-size: 18px;

				&:hover {
					color: rgba($blue-314a8f, 0.7);
				}
			}
		}

		.related-content__content {
			width: 100%;
		}

		&:last-child {
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.container--30-70 &,
		.container--70-30 &,
		.container--50-50 & {
			width: 100%;
		}

		@media(max-width: $bp-max-medium) {
			width: 100%;
		}
	}

	// story mosaic
	.story-mosaic__content & {
		display: none;

		&:first-child {
			border-right: 0;
			display: block;
			margin-right: 0;
			padding-right: 0;
			width: 100%;

			@media(max-width: $bp-max-medium) {
				border-bottom: 0;
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}
	}
}

.related-content__content {
	display: inline-block;
	width: calc(100% - 190px); // 160px image, 30px gutter

	@media(max-width: $bp-max-small-medium) {
		.story-mosaic__content & {
			width: 100%;
		}
	}
}
