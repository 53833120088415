$footer-bp-medium: 1200px;

.footer {

}

.footer__top {
    display: flex;

    .site-logo {
        margin-right: auto;
    }

    .footer__affiliate-dropdown {
        display: block;

				.utility-nav__item {
					color: $white;

					&:hover {
						color: $white; 
					}

					&.is-open span {
						background-color: inherit;
						border-top: none;
					}
				}
    }
    .footer__affiliate-link {
        display: none;
        text-align: center;
        margin: 20px 4px;
    }

    @media(max-width: $bp-max-medium) {
        display: block;

        .footer__affiliate-dropdown {
            display: none;
        }
        .footer__affiliate-link {
            display: block;
        }
    }
}

.footer__main {
    display: flex;

    .newsletter {
        margin-left: auto; // to right
    }

    @media(max-width: $footer-bp-medium) {
        flex-direction: column;

        .newsletter {
            margin-left: 0; // center
            margin-bottom: 28px;
        }
    }
}
