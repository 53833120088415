// buttons.scss
// Define button styles here
// color variables
// base
$base-btn-color: $blue-11193b !default;
$base-btn-bg: $white !default;
$base-btn-border: $white !default;
$base-btn-color--active: $white !default;
$base-btn-bg--active: transparent !default;
$base-btn-border--active: rgba($white, .7)!default;

// primary
$primary-btn-color: #ffffff !default;
$primary-btn-bg: $blue-143577 !default;
$primary-btn-border: darken($primary-btn-color, 60%) !default;
$primary-btn-color--active: $primary-btn-color !default;
$primary-btn-bg--active: darken($primary-btn-bg, 10%) !default;
$primary-btn-border--active: darken($primary-btn-border, 10%) !default;

// secondary
$secondary-btn-color: $purple-663477 !default;
$secondary-btn-bg: transparent !default;
$secondary-btn-border: $purple-b9a2c1 !default;
$secondary-btn-color--active: $white !default;
$secondary-btn-bg--active: $secondary-btn-color !default;
$secondary-btn-border--active: $secondary-btn-color !default;



// base
.btn {
    display: inline-block;
    padding: rem(10) rem(20);

    color: $base-btn-color;
    font-family: $gibson;
    font-size: rem(17);
    line-height: 1.5;
    letter-spacing: .07em;
    text-decoration: none;
    text-transform: uppercase;

    transition: background-color 0.75s cubic-bezier(0.4, 0, 0.2, 1), color 0.75s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In

    background-color: $base-btn-bg;
    border: 2px solid $base-btn-border;
    border-radius: 5px;
    cursor: pointer;

    @media(max-width: $bp-max-medium) {
        font-size: 1rem;
        margin-bottom: rem(20);
    }

    &:active,
    &:hover {
        color: $base-btn-color--active;
        background-color: $base-btn-bg--active;
        border-color: $base-btn-border--active;
    }
}


// modifiers
// primary
.btn--primary {
    color: $primary-btn-color;
    border: 1px solid $primary-btn-border;
    background-color: $primary-btn-bg;

    a {
        color: $primary-btn-color;
    }

    &:active,
    &:hover {
        color: $primary-btn-color--active;
        background-color: $primary-btn-bg--active;
        border-color: $primary-btn-border--active;
    }
}

// primary and disabled
.btn--primary.btn--disabled {
    opacity: 0.3;
}

// primary and ghost
.btn--primary.btn--ghost {
    color: $primary-btn-border;

    &:active,
    &:hover {
        color: $primary-btn-border--active;
    }
}

// secondary
.btn--secondary {
    padding: 12px 24px;


    color: $secondary-btn-color;
    font-size: 17px;
    font-weight: 600;
    line-height: 17px;

    border: 2px solid $secondary-btn-border;
    background-color: $secondary-btn-bg;
    border-radius: 28px;

    &:active,
    &:hover {
        color: $secondary-btn-color--active;
        background-color: $secondary-btn-bg--active;
        border-color: $secondary-btn-border--active;
    }

    &.is-lowercase {
        padding: rem(8) rem(12);
        font-size: rem(15);
        text-transform: none;
        letter-spacing: 0;
        font-family: $stevie;
    }
}


// secondary and disabled
.btn--secondary.btn--disabled {
    opacity: 0.35;
}

// link and reset
.btn--link,
.btn--reset {
    border: 0;
    background-color: transparent;
    text-decoration: underline;

    &:active,
    &:hover {
        text-decoration: none;
        background-color: transparent;
    }

    &[disabled] {
        color: $grey-6c6c69;
		cursor: default;
        text-decoration: none;
    }
}

// disabled
.btn--disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

// ghost
.btn--ghost {
    background-color: transparent;

    &:active,
    &:hover {
        background-color: transparent;
    }
}
// btn tags

.btn--tag {
    padding: rem(2) rem(4);
    font-weight: bold;

    .icon {
        height: rem(16);
        width: rem(16);
        margin-left: rem(4);
        fill: $base-btn-border;
        transition: fill 0.35s;
    }

    &:hover {
        .icon {
            fill: $base-btn-color;
        }
    }
}

// btn sorts
.btn--sort {
    border: 0;
    background-color: transparent;
    overflow: hidden;
}
// btn icon group

.btn--icon-group {
    position: relative;
    padding-right: rem(24);

    .btn_icon-group {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        .icon {
            position: absolute;
            right: 0;
            height: 1.846em;
            max-height: rem(24);
            width: 1.846em;
            max-width: rem(24);
            fill: currentColor;
            opacity: 1;
            visibility: visible;
            transition: opacity 0.35s;

            &:first-child {
                bottom: 35%;
                transform: translateY(35%);
            }

            &:last-child {
                top: 35%;
                transform: translateY(-35%);
            }
        }
    }
}


// button component
.button {
    text-align: center;
    margin-bottom: 30px;
}
