// global-header.scss
// Define global header styles here
$global-header__bottom-space: rem(30);
// base
.global-header {
    background-color: $white;
    box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.15);
    position: relative;
    z-index: 10;

    @media(max-width: $bp-max-medium) {
        .container--bleed {
            background-color: transparent;
        }
    }

    .container:not(.is-adjacent) {
        margin-bottom: $global-header__bottom-space;
    }

    .container--bleed {
        .container__col {
            @include outer-container();
        }
    }
}

.global-header__top {
    @include clearfix;
    margin-bottom: rem(16);

    .utility-nav {
        margin-top: rem(30);
    }

    @media(max-width: $bp-max-medium) {
        text-align: center;
        margin-bottom: 1rem;
        width: 100%;
    }

    @media(max-width: $bp-max-small) {
        margin-bottom: 0;
    }
}


.global-header__top-right {
    float: right;
    position: relative;

    .utility-nav {
        margin-bottom: rem(8);
        margin-right: rem(60);
        float: left;
    }

    .utility-nav__list {
        float: right;
    }

    @media(max-width: $bp-max-medium) {
        display: none;
    }

    .search-box {
        float: right;
        margin-top: rem(15);
    }
}

.global-header__top-left {
    float: left;
    margin-top: 1.875rem;

    @media(max-width: $bp-max-medium) {
        display: inline-block;
        float: none;
    }

    @media(max-width: $bp-max-small) {
        margin-top: 20px;
    }
}

.global-header__bottom {
    position: relative;

    .search-box {
        display: none;
        float: right;
    }

    @media(max-width: $bp-max-medium) {
        .search-box {
            display: block;
            position: absolute;
            right: 10px;
            top: rem(22);
        }
    }

    @media(max-width: $bp-max-small) {
        .search-box {
            top: rem(10);
        }
    }
}

.global-header__search-form {
    margin-left: rem(7);

    // responsive layout
    @media(max-width: 740px) {
        display: block;
        margin-left: 0;
        margin-bottom: rem(12);
    }
    @media(max-width: 560px) {
        margin: rem(12) auto;
        width: 90%;
    }
}
