// image-block.scss

// base
.image-block{
    @include component-whitespace();

    // story mosaic
    .story-mosaic__content & {
        padding: 30px;
    }
}
// image media
.image-block__media{

    img{
        display: block;
        width: 100%;
    }
    // figcaption
    figcaption{
        color: $grey-a3a3a1;
        font-size: rem(14);
        font-style: italic;
        line-height: 1.45;
        max-width: 700px;
        margin-left: auto;
        padding-bottom: em(10);
        padding-top: em(10);
        text-align: right;
    }

	figcaption + .image-block__link {
		margin-top: 0;
	}

	@media(max-width: $bp-max-medium) {
		figcaption {
			font-size: rem(18);
		}
	}

    
}

.image-block__link {
    display: block;
    text-align: right;
	margin-top: .8rem;

    &:hover {
        opacity: 0.8;

        &::after {
            transform: translateX(3px);
        }
    }

    &::after {
        font-family: $stevie;
        font-weight: 500;
        content: ' _>';
        display: inline-block;
        margin-left: 5px;
        transition: transform 450ms;
    }
}
