.homepage__heading {
    text-align: center;
    margin-bottom: 40px;
}

.homepage__hr {
    width: 120px;
    height: 2px;
    background-color: $grey-d1d1d0;
    border: 0;
    margin: 80px auto;
}
