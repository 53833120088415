$rich-text__bottom-space: 30;
$rich-text__font-size: 18;
$rich-text__video-width: rem(410);

.rich-text {
	@include component-whitespace;
	font-size: rem($rich-text__font-size);
	line-height: 1.7;

	.story-mosaic__content & {
		margin: 0;
		padding: rem(50);
	}

	// all textual element on the page should receive a max-width
	// and be centered. Embeds should continue to be 100%;
	&>div:not(.rich-text__embed),
	p,
	ul,
	ol,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	blockquote,
	table {
		margin-left: auto;
		margin-right: auto;
	}


	p, li {
		font-size: inherit;

		& a {
			font-weight: bold;
		}
	}

	p {
		margin-bottom: 2rem;
	}

	blockquote {

    font-size: 1.3rem;
		line-height: 1.6;

		@media(max-width: $bp-max-small) {
				margin: rem(20);
		}

	}

	ul, ol {
		padding-left: rem(20);
		list-style-position: outside;
	}

	ol,
	ul {
	    line-height: 1.6;

	    li {
	        // margin-left: rem(25);
	        padding-left: rem(10);
			padding-right: rem(25);
			position: relative;
				left: rem(25);

	        ul ul li,
	        ol ol li {
	          margin-left: rem(55);
	        }
	    }
	}

	hr {
		margin-bottom: rem($rich-text__font-size);
	}

	table {
		clear: both;
		margin-top: rem(45);
		margin-bottom: rem(45);

		caption {
			margin-bottom: rem($rich-text__bottom-space / 2);
			font-weight: bold;
		}
	}


}

div, section{
	// full width
	&.l-rte-full {
			width: 100%;
	}

	// float left
	&.l-rte-pull-left {
			float: left;
			margin-bottom: rem(30);
			margin-right: rem(30);
	}

	// float right
	&.l-rte-pull-right {
			float: right;
			margin-bottom: rem(30);
			margin-left: rem(30);
	}

	.rich-text__embed {
		&:not(.l-rte-pull-right),
		&:not(.l-rte-pull-left) {
			display: flex;
			justify-content: center;
			max-width: 100%;

			h1, h2, h3, h4, h5, h6 {
				max-width: none;
			}

			.image-block__media {
				text-align: center;

				figcaption {
					text-align: right;
				}
			}

			.video-block {
				width: 100%;
			}

			.video-block__media {
				width: 100%;
			}

		}

		&.l-rte-pull-left,
		&.l-rte-pull-right {
			max-width: 40%;

			.video-block,
			.image-block {
				margin-bottom: 0;
			}

			.video-block__caption,
			.image-block__media figcaption {
				padding-bottom: 0;
			}

			.image-block__media {
				border-bottom: 0;

			}

			.video-block,
			.video-block__media {
				// don't use percentages here
				// embed parent has no explicit width
				min-width: $rich-text__video-width;
			}

			.video-block__media-placeholder {
				// for consistency, restrain
				// placeholder image to video width
				width:  $rich-text__video-width;
			}


			@media(max-width: $bp-max-medium) {
				float: none;
				width: 100%;
				max-width: none;
				margin-left: 0;
				margin-right: 0;

				.video-block,
				.video-block__media {
					min-width: 100%;
				}
			}
		}

		.image-block__media {
			img {
				max-width: 100%;
				width: auto;
			}
		}
	}

	.rich-text__subheader {
		max-width: 850px;
		margin: 50px auto 90px;
		font-family: "merriweather",Georgia,"Times New Roman",Times,serif;
		font-weight: 300;
		color: #353531;
		font-size: 24px;
		font-size: 1.5rem;
		line-height: 1.5;
		letter-spacing: -.01em;
	}
}

.container--100 {
	.rich-text {
		margin-left: auto;
		margin-right: auto;
	}
}

.rich-text--breakout {
	max-width: none;

}
