.content-feature {
    padding-bottom: rem(32);
}

.content-feature__eyebrow {
    font-family: $gibson;
    font-weight: 600;
    color: $gold-a58328;
    font-size: rem(14.4);
    text-transform: uppercase;
    margin-bottom: rem(2);
    letter-spacing: 0.07em;
}

.content-feature__img {
    margin-bottom: 12px;
}

.content-feature__title {
    font-size: rem(24);
    margin-bottom: rem(8);

    a {
        color: $grey-353531;

        &:hover {
            color: rgba($grey-353531, 0.7);
        }
    }
}

.content-feature__meta {
    @include clearfix;
    line-height: 1.5;
    margin-bottom: rem(15);
    font-size: rem(13);
    color: $grey-a3a3a1;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    font-weight: 500;

    &:empty {
        display: none;
        margin: 0;
        padding: 0;
        border: 0;
    }
}

.content-feature__author {

    &:after {
        content: "; ";
    }

    &:last-child:after {
        content: "";
    }
}

.content-feature__date,
.content-feature__time {
    display: block;
}
