$height: 38px;
$icon-size: 24px;
$icon-padding: (($height - $icon-size) / 2);
$right-padding: 5px;

.search-keyword__input {
    position: relative;
    margin-bottom: 40px;

    input {
        position: relative;
        width: 100%;
        padding: 10px ($icon-size + $icon-padding + $right-padding) 10px 15px;

        font-size: 16px;
        font-weight: 400;
        line-height: 26px;

        border: 1px solid $grey-d1d1d0;
        border-radius: 5px;
    }

    // magnifying glass
    svg {
        position: absolute;
        right: $right-padding;
        top: 50%;
        transform: translateY(-50%);
        width: $icon-size;
        height: $icon-size;
        padding: $icon-padding;
        box-sizing: content-box;
        cursor: pointer;

        fill: $blue-314a8f;
    }
}
