.search {
}

.search__query-mount {
	@include clearfix;
	.search-box {
		margin-bottom: rem(60);
		max-width: rem(900);
	}

	@media (max-width: $bp-max-medium) {
		.search-box {
			max-width: 100%;
			width: 100%;
			float: none;
		}
	}
}

@media (max-width: $bp-max-medium) {
	.search__facets-wrapper {
		position: relative;
		margin: 0;

		&.is-left,
		&.is-right {
			position: absolute;
			background-color: $grey-d1d1d0;
			width: 85vw;
			top: 0;
			transition: transform 0.3s ease-in;
			margin: 0;
			padding: rem(30);
			z-index: 100;
			&.is-open {
				transform: translateX(0%);
			}
		}

		&.is-left {
			left: rem(-30);
			transform: translateX(-100%);

		}


		&.is-right {
			right: rem(-30);
			transform: translateX(100%);
			transition: transform 0.3s ease-in;
		}

		&.is-open {
			border: 1px solid $base-border-color;
			border-left: 0;
			box-shadow: -1px 4px 12px 1px $grey-353531;
		}

		.search-facets__group {
			background-color: $white;
		}
	}
}
