.dropdown {
    position: relative;

}

.dropdown__trigger {
    padding-right: 28px !important;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;

    // $gold-a58328 down arrow
    &::after {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 12px;
        height: 8px;
        background-image: url("/assets/img/svg-sprite/arrow.svg");
        background-repeat: no-repeat;
        content: "";
        transition: all 250ms;
        @media(max-width: $bp-max-medium) {
            top: 12px;
            right: 12px;
        }
    }

    .is-open>&::after {
        transform: rotate(180deg);
    }
}


.dropdown__submenu {
    position: absolute;
    display: block;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.4s ease-out;
    z-index: 100;
    min-width: 250px;

    .is-open & {
        height: auto;
        opacity: 1;
    }
}
