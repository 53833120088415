.people-listing {

}

.people-listing__title {
	text-align: center;
	margin-bottom: 50px;
}

.people-listing__content {
	display: flex;
	flex-wrap: wrap;
}

.people-listing__item {
	flex-basis: calc(33% - 13.33px);
	margin-right: 20px;
	margin-bottom: 50px;

	&:nth-child(3n) {
		margin-right: 0;
	}

	.container--50-50 & {
		@media(max-width: $bp-max-large) {
			flex-basis: calc(50% - 10px);

			&:nth-child(3n) {
				margin-right: 20px;
			}

			&:nth-child(2n) {
				margin-right: 0;
			}
		}

		@media(max-width: $bp-max-small) {
			flex-basis: 100%;
			margin-right: 0;

			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}

	@media(max-width: $bp-max-medium) {
		flex-basis: calc(50% - 10px);

		&:nth-child(3n) {
			margin-right: 20px;
		}

		&:nth-child(2n) {
			margin-right: 0;
		}
	}

	@media(max-width: $bp-max-small) {
		flex-basis: 100%;
		margin-right: 0;

		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}

.people-listing__image {
	// border-radius: 50%; TBF-265
	width: 160px;
    height: 160px;
}

.people-listing__image-container {
	height: 160px;
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	width: 160px;
}

.people-listing__description {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 10px;
	text-align: center;
}

.people-listing__link {
	display: block;
	font-size: 16px;
	text-align: center;

    &:hover {
        opacity: 0.8;
		&::after {
			transform: translateX(3px);
		}
	}

	&::after {
		font-family: $stevie;
	    font-weight: 500;
	    content: ' _>';
	    margin-left: 5px;
	    display: inline-block;
	    transition: transform 450ms;
	}
}
