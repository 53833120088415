// typography.scss
// Define typography styles here

// typographic variables

/// '$helvetica' variable overrides bourbon's font-stack variable of the same name
/// @author thoughtbot
/// @link https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_font-stacks.scss
$gibson: "canada-type-gibson", Helvetica, Arial, sans-serif;
$merriweather: "merriweather", Georgia, "Times New Roman", Times, serif;
$stevie: "stevie-sans", Helvetica, Arial, sans-serif;

/// Font Stack Map to define wireframe font-stack
/// @author Hmphry
/// @link http://hmphry.com/useful-sass-mixins
///
/// @example scss - usage
///     h1 {
///
///     }
///
///     p {
///
///     }
/// @example css - CSS output
///    h1 {
///        font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
///        font-weight: 400;
///        font-style: normal;
///    }
///
///    p {
///        font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
///        font-weight: 600;
///        font-style: normal;
///    }
///
/// TODO: Update with your font-stack scheme and call the font() function too!
/// NOTE: Needs a trailing semi-colon on the last declaration!
// $font-stack:
//   (group: 'stevie-sans', id: book, font: ($stevie), weight: 400, style: normal),
//   (group: 'stevie-sans', id: regular, font: ($stevie), weight: 500, style: normal),
//   (group: 'stevie-sans', id: bold, font: ($stevie), weight: 700, style: normal),
//   (group: 'canada-type-gibson', id: semibold, font: ($gibson), weight: 600, style: normal),
// 	(group: 'canada-type-gibson', id: semibold-italic, font: ($gibson), weight: 600, style: italic),
// 	(group: 'merriweather', id: light, font: ($merriweather), weight: 300, style: normal),
// 	(group: 'merriweather', id: light-italic, font: ($merriweather), weight: 300, style: italic),
// 	(group: 'merriweather', id: bold, font: ($merriweather), weight: 700, style: normal);
//
// @mixin font($group, $id:regular) {
//     @each $font in $font-stack {
//         @if ($group == map-get($font, group) and $id == map-get($font, id)) {
//             font-family: map-get($font, font);
//             font-weight: map-get($font, weight);
//             font-style: map-get($font, style);
//         }
//     }
// }

// color variables
$base-body-color: $grey-595956 !default;

// base styles
body {
    font-family: $stevie;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.25;
    color: $base-body-color;
    -webkit-font-smoothing: antialiased;
}

// headings
h1, h2, h3, h4, h5, h6,
.txt-h1, .txt-h2, .txt-h3, .txt-h4, .txt-h5, .txt-h6 {
    @include zero;
    font-family: $gibson;
    font-weight: 600;
    color: $grey-353531;
    line-height: 1.3;
}

h1, h2, h3 {
    margin-bottom: rem(12);
}

h4, h5, h6 {
    margin-bottom: rem(9);
}

h1,
.txt-h1 {
    font-size: rem(56);
    line-height: 1.2;

    @media(max-width: $bp-max-medium) {
      font-size: 2.2rem;
    }

    @media(max-width: $bp-max-small) {
        font-size: 1.8rem;
        line-height: 1.1;
    }
}

h2,
.txt-h2 {
    font-size: rem(38);

    @media(max-width: $bp-max-medium) {
      font-size: rem(30);
    }

    @media(max-width: $bp-max-small) {
        line-height: 1.1;
    }
}

h3,
.txt-h3 {
    font-size: rem(30);

    @media(max-width: $bp-max-medium) {
      font-size: rem(28);
    }

    @media(max-width: $bp-max-small) {
        font-size: rem(24);
        line-height: 1.1;
    }
}

h4,
.txt-h4 {
    font-size: rem(24);

    @media(max-width: $bp-max-medium) {
      font-size: rem(22);
    }

    @media(max-width: $bp-max-small) {
        font-size: rem(20);
        line-height: 1.1;
    }
}

h5,
.txt-h5 {
    font-size: rem(20);
    text-transform: uppercase;

    @media(max-width: $bp-max-medium) {
      font-size: rem(18);
    }
}

h6,
.txt-h6 {
    font-size: 1rem;
    font-family: $gibson;
    font-weight: 600;
    letter-spacing: .02em;
}

// paragraphs
p {
    @include zero;
    margin-bottom: rem(18);
    font-size: rem(18);
    line-height: 1.7;
}

// small text
.txt-small{
    font-size: rem(14);
}
