/* Accordion styles */

.accordion {
    .tab {
        position: relative;
        margin-bottom: 1px;
        width: 100%;
        color: #fff;
        overflow: hidden;
        font-family: "stevie-sans", Helvetica, Arial, sans-serif;

        input {
            position: absolute;
            opacity: 0;
            z-index: -1;
        }

        label {
            position: relative;
            display: block;
            padding: 0 0 0 1em;
            background: #a58328;
            font-weight: bold;
            line-height: 3;
            cursor: pointer;
            font-family: "stevie-sans", Helvetica, Arial, sans-serif;
            background: #a58328;
        }
    }

    .tab-content {
        max-height: 0;
        overflow: hidden;
        background: #a58328;
        -webkit-transition: max-height .35s;
        -o-transition: max-height .35s;
        transition: max-height .35s;
        font-family: "stevie-sans", Helvetica, Arial, sans-serif;
    }

    .tab .tab-content {
        background: #fff;
        border: 1px solid #a58328;
        color: #595956;
    }

    .tab .tab-content .rich-text{
        margin-bottom: 0;
    }

    .tab-content p {
        margin: 1em;
    }

    .tab {
        input:checked~.tab-content {
            max-height: 100vh;
            overflow: auto;
        }

        label::after {
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            width: 3em;
            height: 3em;
            line-height: 3;
            text-align: center;
            -webkit-transition: all .35s;
            -o-transition: all .35s;
            transition: all .35s;
        }

        input {

            &[type=checkbox]+label::after,
            &[type=radio]+label::after {
                content: "+";
            }

            &[type=checkbox]:checked+label::after,
            &[type=radio]:checked+label::after {
                transform: rotate(315deg);
            }
        }
    }
}
