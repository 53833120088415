$search-item-media-margin: rem(30);

.search-item {
    opacity: 0;
    transition: opacity 250ms;
    margin-bottom: rem(32);
    padding-bottom: rem(32);
    border-bottom: 1px solid $base-border-color;
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    &:last-child {
        border-bottom: 0;
    }

    &.is-visible {
        opacity: 1;
    }

    .search-item__media {
        margin-right: $search-item-media-margin;
        margin-bottom: 0;

        img {
            width: 160px;
        }
    }

    .sharebarInline {
        margin: rem(15) 0;
    }
    // @media(max-width: $bp-max-large) {
    //     flex-direction: column;
    //
    //     .search-item__media {
    //         margin-bottom: $search-item-media-margin;
    //         margin-left: 0;
    //         margin-right: $search-item-media-margin;
    //     }
    // }

    @media(max-width: $bp-max-large) {
        flex-direction: row;
    }

    @media(max-width: 650px) {
        flex-direction: column;
    }
}



.search-item__media {
    margin-bottom: $search-item-media-margin;
}

.search-item__content {
    flex: 1;
    margin-top: 0;

    @media(max-width: $bp-max-medium) {
        margin-top: 0;
    }

}

.search-item__eyebrow {
    font-family: $gibson;
    font-weight: 600;
    color: $gold-a58328;
    font-size: rem(14.4);
    text-transform: uppercase;
    margin-bottom: rem(2);
    letter-spacing: 0.07em;
}

.search-item__title {
    font-size: rem(24);
    margin-bottom: rem(8);

    a {
        color: $grey-353531;

        &:hover {
            color: rgba($grey-353531, 0.7);
        }
    }
}

.search-item__description {
    max-width: 800px;
    font-size: rem(16);
    margin-bottom: rem(15);

		 @media(max-width: $bp-max-small) {
        display: none;
    }
}

.search-item__meta {
    @include clearfix;
    line-height: 1.5;
    margin-bottom: rem(15);
    font-size: rem(13);
    color: $grey-a3a3a1;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    font-weight: 500;

    &:empty {
        display: none;
        margin: 0;
        padding: 0;
        border: 0;
    }
}

.search-item__date,
.search-item__time {
    display: block;
}

.search-item__byline {
    display: block;
}

.search-item__cta {
    display: block;
    text-align: left;
}

.search-item__author {
    &:after {
        content: "; ";
    }

    &:last-child:after {
        content: "";
    }
}

.search-item__play-video {

    margin-top: 15px;
    color: $blue-314a8f;
    font-size: 18px;
    font-weight: 700;
    line-height: 29px;

    a {
        display: inline-flex;
        align-items: center;
    }

    .svg-play-video {
        fill: currentColor;
        margin-right: 8px;
    }
}
