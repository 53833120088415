// connect variables
$connect-icon-size: rem(35) !default;
$connect-icon-spacing: rem(4) !default;
$connect-icon-fill: $white !default;
$connect-phone-size: rem(24) !default;
$connect-promo-size: rem(24) !default;
$connect-bp-small: em(570px) !default;

.connect {
  border-bottom: 1px solid rgba($white,.2);
  padding-bottom: rem(10);
}

.connect__button {
    line-height: $connect-icon-size;
    padding: 0 rem(20) 0 0;
    white-space: nowrap;

    @media(max-width: $bp-max-small) {
        padding: 0 0 rem(12) 0;
    }
}

.connect__icon {
    margin-right: 15px;
    order: 1;
}

.connect__contact {
    display: flex;
    padding: rem(12) 0;
    order: 3;
    margin-right: auto; // will grow
    font-family: $gibson;

    @media(max-width: $bp-max-medium) {
        padding: 0 0 rem(12) 0;
    }

    @media(max-width: $connect-bp-small){
        justify-content: center;
        margin-right: 0; // don't grow
    }

    @media(max-width: $bp-max-small) {
        display: block;
        padding: 0;
    }
}

.connect__inner {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media(max-width: $bp-max-large) {
        padding: 0 rem(30);
    }

    @media(max-width: $bp-max-medium) {
        flex-wrap: wrap;
    }

    @media(max-width: $connect-bp-small){
        text-align: center;
        justify-content: center;
    }

    @media(max-width: $bp-max-small) {
        display: block;
        text-align: center;
    }
}

.connect__phone {
    font-size: $connect-phone-size;
    font-weight: 700;
    line-height: $connect-icon-size;
    order: 2;
    padding: 0 rem(20) 0 0;
    white-space: nowrap;

    @media(max-width: $bp-max-small) {
        padding: 0 0 rem(12) 0;
    }

    a {
        color: currentColor;
        text-decoration: none;

        &:hover {
          color: rgba($white,.7);
        }
    }
}

.connect__promo-statement {
    font-family: $merriweather;
    font-size: $connect-promo-size;
    font-weight: 300;
    flex-grow: 0;
    line-height: $connect-icon-size;
    order: 2;
    padding: rem(12) rem(5) rem(12) 0;

    @media(max-width: $bp-max-medium) {
        order: 0;
        padding: rem(12) 0;
        width: 100%;
    }
}

.connect__social {
    padding: rem(12) 0;
    order: 4;
    white-space: nowrap;

    @media(max-width: $bp-max-medium) {
        padding: 0 0 rem(12) 0;
    }

    @media($connect-bp-small){
        width: 100%;
        text-align: center;
    }

    a {
        display: inline-block;
        margin-left: $connect-icon-spacing;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }
    }

    span {
      font-family: $gibson;
    	font-weight: 600;
    	color: rgba($connect-icon-fill,.8);
    	font-size: rem(14.4);
    	text-transform: uppercase;
    	letter-spacing: .07em;
      display: block;
      margin: rem(13) rem(8) 0 0;
      float: left;

      @media(max-width: $bp-max-small) {
          display: none;
      }
    }
}

.connect__social-icon {
    fill: rgba($connect-icon-fill,.8);
    height: $connect-icon-size;
    width: $connect-icon-size;
}
