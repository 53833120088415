.alert {
    background-color: $gold-a58328;
    padding: 15px 20px;

    display: none; // initially hidden

    &.is-visible {
        display: block;
    }

    .alert__inner {
        display: flex;
        align-items: center;

        .alert__text {
            flex: auto;
            margin-right: 10px;
        }

        .alert__close {
            flex: none;
            margin-left: auto;
        }

        @media(max-width: $bp-max-medium) {
            margin: 0;
        }
    }
}

.alert__text-title {
    letter-spacing: 0.07em;
}

.alert__text {
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;

    strong {
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.alert__close {
    cursor: pointer;

    .svg-close {
        fill: white;
        width: 18px;
    }
}
