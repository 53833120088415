.arrow-link {
    font-weight: bold;
    font-size: rem(16);

    &:hover {

        &::after {
            transform: translateX(3px);
        }
    }

    &::after {
        content: ' _>';
        display: inline-block;
        transition: transform 450ms;
        margin-left: rem(4);
        font-family: $stevie;
    }
}

.arrow-link-down {
    font-weight: bold;
    font-size: rem(16);

    &::after {
        content: ' _>';
        display: inline-block;
        transition: transform 450ms;
        margin-left: rem(4);
        font-family: $stevie;
        transform: rotate(90deg);
    }
}