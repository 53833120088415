$year-size: 130px;

.container--year-promo {
    max-width: 1068px;
    margin: 0 auto 100px;
    padding: 10px;
}

.year-promo-section__title {
    text-align: center;
}

.year-promo-section__blurb {
    max-width: 850px;
    margin: 50px auto 90px;
    font-family: $merriweather;
    font-weight: 300;
    color: $grey-353531;
    font-size: rem(24);
    line-height: 1.5;
    letter-spacing: -.01em;

    .container--30-70 &,
    .container--70-30 & {
        max-width: 750px;
    }

    @media(max-width: $bp-max-medium) {
        font-size: 20px;
        margin-bottom: 30px;
    }
}

.year-promo-section__cta {
    text-align: center;
}

.year-promo-section__promos {
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1068px;
}

.year-promo {

    .media-object__img {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 260px;
        padding-top: 60px;
        padding-bottom: 30px;
    }

    .media-object__content {
        padding-bottom: 70px;
        margin-top: 15px;
    }


    // $gold-a58328 line between year
    &:not(:last-child) {
        .media-object__img::before {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: $year-size/2;
            width: 2px;
            background-color: rgba($gold-a58328, 0.2);
            content: " ";
        }
    }

    &:not(:last-child) {
        .is-open & {
            .media-object__img::before {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: $year-size/2;
                width: 2px;
                background-color: rgba($gold-a58328, 0.2);
                content: " ";
            }
        }
    }

    &.content-list__overflow {
        display: none;
    }

    @media (max-width: $bp-max-medium){
        &.media-object {
            flex-direction: column;
            align-items: center;
        }

        // hide $gold-a58328 line between year
        &:not(:last-child) .media-object__img::before {
            display: none;
        }

        &:last-child {
            .media-object__content {
                padding-bottom: 10px;
            }
        }
    }

}

.year-promo__img {
    display: block;
    height: 160px;
    width: 160px;

    img {
        // border-radius: 50%; TBF-265
        max-width: 100%;
    }
}

.year-promo__year {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $year-size;
    width: $year-size;
    top: 0;
    left: 0;

    color: $gold-a58328;
    font-family: $merriweather;
    font-size: 38px;
    font-weight: 700;

    background-color: white;
    border: 2px solid $gold-a58328;
    border-radius: 50%;

}

.year-promo__title {
    margin-bottom: 15px;
    color: $grey-353531;
    font-family: $gibson;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
}

.year-promo__text {
    margin-bottom: 20px;
    color: $grey-595956;
    font-family: $stevie;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7;

    @media (max-width: $bp-max-medium){
        font-size: 16px;
    }
}
