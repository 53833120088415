.page-meta {
	@include component-whitespace();
	color: $grey-a3a3a1;

	&__date {
		font-style: italic;
	}

	&__byline {
		border-left: 1px solid $grey-6c6c69;
		margin-left: .5em;
		padding-left: .75em;
	}

	&__subtitle {
		font-size: 15px;
	}

	&__author {
		font-size: 16px;
		line-height: 26px;
		text-transform: uppercase;

		a {
			font-weight: 400;
		}
	}
}