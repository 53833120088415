$secondary-nav-small-bp: rem(700);

.secondary-nav {
	.multi-promo__promo {
		font-family: $gibson;
		font-weight: bold;

		a {
			font-size: 30px;
			color: $grey-353531;

			@media(max-width: $bp-max-medium) {
				font-size: 24px;
			}
			@media(max-width: $bp-max-small) {
				font-size: 22px;
			}
		}

		@include exactly(4) {
			width: 25%;

			@media(max-width: $secondary-nav-small-bp) {
				width: 50%;
			}
		}

		@media(max-width: $secondary-nav-small-bp) {
			width: 50%;
		}
	}

	.promo-icon-link__link {
		border-top: 0;

		&:after {
			margin-left: 5px;
		}
	}
}