.page-title {
	max-width: rem(850);
	margin: auto;
}

.page-title__subtitle {
	font-family: $merriweather;
	font-weight: normal;
	font-size: 28px;
	line-height: 42px;


    @media(max-width: $bp-max-medium) {
		font-size: 24px;
		line-height: 30px;
    }
}

.page-title__type {
	text-transform: uppercase;
	margin-bottom: 0;
	color: $grey-353531;
	font-family: $gibson;
}
