// content-list.scss
// Define content list styles here
$bp-max-content-list: em(770);

// base
.content-list {
    @include component-whitespace();
    padding-top: 50px;
}

.content-list__heading {
    margin-bottom: rem(50);
    text-align: center;
}

.content-list__subtitle {
    font-family: $merriweather;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 50px;
}

.content-list__overflow {
    display: none;
}

// list
.content-list__list {
    @include zero-list;
    display: flex;
    flex-wrap: wrap;
}

.content-list__items {
    margin: 0 0 40px;

    .content-list__item {
        border-bottom: 1px solid $base-border-color;
        width: 100%;

        &:first-child {
            padding-right: 0;

            img {
                max-width: 300px;

                .content-list__overflow & {
                    max-width: 60px;
                }
            }

            .list-item__content {
                display: block;

                .container--50-50 & {
                    @media(max-width: $bp-max-large) {
                        width: 100%;
                    }
                }

                @media(max-width: $bp-max-content-list) {
                    width: 100%;

                    .content-list__overflow & {
                        width: calc(100% - 95px);
                    }
                }
            }
        }

        &:nth-child(2n) {
            border-left: 0;
            padding-left: 0;
        }

        // all but the first
        &:nth-child(n+2) {
            img {
                max-width: 60px;
            }

            .list-item__content {
                display: inline-block;
                width: calc(100% - 95px); //img + margin

                @media(max-width: $bp-max-small) {
                    width: 100%;
                }
            }
        }

        &:last-child {
            .is-open & {
                border-bottom: 1px solid $base-border-color;
                padding-bottom: rem(40);
            }

            .content-list__overflow & {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }

        .list-item {
            display: flex;
            flex-direction: row;
            font-size: 0;

            .list-item__media {
                flex: none;
            }

            .list-item__content {
                flex: auto;
            }
        }

        .list-item__media {
            max-width: 335px;

            // hide images on small screens
            @media(max-width: $bp-max-small) {
                display: none;
            }

        }


        @media(min-width: $bp-min-large) {
            @include exactly(3) {
                width: 100%;
            }

            width: 100%;

        }
    }

    .list-item__media {
        margin-right: 35px;
    }

    .list-item__description {
        font-size: 18px;
    }
}

// items
.content-list__item {
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid $base-border-color;
    padding-bottom: rem(40);
    margin-bottom: rem(40);

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    @media(min-width: $bp-min-large) {
        @include exactly(3) {
            width: 33.333%;
        }

        border-bottom: none;
        width: 50%;

        &:nth-child(1n) {
            padding-right: rem(45);
        }

        &:nth-child(2n) {
            border-left: 1px solid $base-border-color;
            padding-left: rem(45);
        }
    }

    @media(width: rem(600)) {
        width: 100%;
    }
}

.content-list__more {
    text-align: center;
}
