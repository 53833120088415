.featured-content {
    background-color: $gold-f6f3eb;
    border-top: 2px solid $gold-a58328;
}

.featured-content__title {
    padding: rem(54) rem(10);
    color: $gold-a58328;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.07em;
    text-align: center;
    text-transform: uppercase;
}

.featured-content__all-items {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px 20px;

    // left and right sections
    .featured-content__with-images,
    .featured-content__bare-links {
        flex-basis: 50%;
        flex-shrink: 0;
        flex-grow: 1;
        padding: 0 40px;
        box-sizing: border-box;

        // stack on medium screens
        @media(max-width: $bp-max-small) {
            flex-basis: 100%;
        }
    }
}

.featured-content__with-images {

    // adjust the media-object so the text is veritally centered with the image
    .media-object__content {
        display: flex;
        align-items: center;
        margin: 0;

        @media(max-width: $bp-max-medium) {
            justify-content: center;
        }
    }

    // contrain the image, and make round
    img {
        max-width: 110px;
        max-height: 110px;
        // border-radius: 50%; TBF-265
    }

    // stack the image and text on small screens
    @media(max-width: $bp-max-medium) {
        .media-object {
            flex-direction: column;
        }

        .media-object__img {
            margin: 0 0 10px 0;
            text-align: center;
        }
	}
}


.featured-content__item {

    border-top: 1px solid $gold-d7c79e;
    padding-top: 18px;
    padding-bottom: 18px;

    .arrow-link {
        color: #353531;
        font-size: 21px;
        font-weight: 600;
    }

    &:first-child {
        border-top: none;
    }

    .container--100 .featured-content__all-items > :not(:first-child) &:first-child {
        @media(max-width: $bp-max-small) {
            border-top: 1px solid $gold-d7c79e;
        }
    }

    .container--50-50 .featured-content__all-items > :not(:first-child) &:first-child {
        border-top: 1px solid $gold-d7c79e;
    }
}
