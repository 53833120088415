.callout {
	.multi-promo {
		justify-content: center;
		margin-bottom: 0;
	}

	.promo-icon-link {
		margin-bottom: 0;
	}

	.promo-icon-link__link {
		@media(max-width: $bp-max-medium) {
			font-size: 22px;
			padding: 1em;
		}
	}

	@media(min-width: $bp-min-medium) {
		.multi-promo__promo {
			&:first-child:last-child {
				width: 50%;
			}
		}
	}
}