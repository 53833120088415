.breadcrumbs {
    @include component-whitespace();
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    max-width: $max-width;
    margin: auto;

    @media (max-width: $bp-max-medium) {
        flex-direction: column-reverse;

        .breadcrumbs__social {
            align-self: flex-end;
        }
    }
}

.container--breadcrumbs {
    border-bottom: 2px solid $base-border-color;
    padding-bottom: em(10);
    margin-bottom: 100px;
    padding: 10px 10px;
}

.breadcrumbs__list {
    @include zero-list;
    font-size: rem(14);
    margin-right: auto;
}

.breadcrumbs__social {
    white-space: nowrap;
    margin-left: auto; // push right when wrapped

    a {
        display: inline-block;
    }
}

.breadcrumbs__social-label {
    display: inline-block;
    margin-right: 6px;

    color: $grey-a3a3a1;
    font-family: $gibson;
    font-size: 13px;
    font-weight: 600;
    line-height: 35px;
    text-transform: uppercase;
    vertical-align: middle;
}

.breadcrumbs__social-icon {
    fill: currentColor;
    height: 30px;
    width: 30px;
    vertical-align: middle;
}

// breadcrumbs item
.breadcrumbs__item {
    display: inline-block;
    margin-bottom: 0;
}

.breadcrumbs__divider {
    color: $base-body-color;
    margin: 0 8px;
}
