.stat {
	.multi-promo--alt {
		margin-bottom: 20px;
	}

	.card & {
		background-color: rgba($blue-314a8f, 0.8);
		padding: 50px;

		@media(max-width: $bp-max-small) {
			padding: 25px;
		}
	}
}

.stat__stat-container {
	border-bottom: 2px solid $grey-d1d1d0;
	color: $blue-314a8f;
	font-family: $merriweather;
	line-height: initial;
	padding-bottom: 10px;
	text-align: center;

	// story mosaic
	.card & {
		color: $white !important; // override important
		border-bottom: 2px solid $white;
	}
}

.stat__prefix {
	vertical-align: text-bottom;
}

.stat__prefix,
.stat__suffix {
	font-size: 60px;
	font-weight: normal;

	@media(min-width: $bp-min-medium) {
		font-size: 40px;
	}

	@media(min-width: $bp-min-x-large) {
		font-size: 80px;
	}
}

.stat__content {
	font-size: 120px;
	font-weight: bold;

	@media(min-width: $bp-min-medium) {
		font-size: 74px;
	}

	@media(min-width: $bp-min-large) {
		font-size: 100px;
	}

	@media(min-width: $bp-min-x-large) {
		font-size: 150px;
	}
}

.stat__description {
	font-size: 16px;
	font-weight: normal;
	line-height: 24px;
	text-align: center;

	.card & {
		color: $white !important;
	}
}
