// general.scss
// Define universal plain tag styles here
// NOTE: No classes or IDs!

// color variables
$base-link-color: $blue-314a8f !default;
$base-border-color: $grey-595956 !default;

// body element
body {
    margin: 0;

    // stevie sans arrow
    font-variant-ligatures: contextual;
    -moz-font-feature-settings: "calt";
    -webkit-font-feature-settings: "calt";
    font-feature-settings: "calt";
}

// annotate breakpoints inside body psuedo-selector
// so they may be read by javascript
body::before {
    content: 'large';
    display: none;


    @media(max-width: $bp-max-medium) {
        content: 'medium';
    }

    @media(max-width: $bp-max-small) {
        content: 'small';
    }
}



// flexible media
object,
video {
    display: block;
    max-width: 100%;
}

// lists
ol,
ul {
    padding: 0;
    margin-top: 0;
    margin-left: rem(60);
    margin-bottom: rem(30);

    li {
        margin-bottom: rem(10);
    }
}

// nested lists
ul ul,
ol ul,
ol ol,
ul ol {
    margin-top: rem(10);
}

// definition lists
dl{
    padding: 0;
    margin-top: 0;
    margin-left: rem(60);
    margin-bottom: rem(30);

    dd, dt {
        margin-bottom: rem(10);
    }
}

// links
a{
    color: $base-link-color;
    transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1), color 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In
    text-decoration: none;
    font-weight: 700;

    &:hover {
        color: rgba($base-link-color, .7);
    }
}

// hr
hr {
    border-bottom: 1px solid $base-border-color;
    border-left: none;
    border-right: none;
    border-top: none;
    margin: -1px 0 0 0;
    box-sizing: content-box;
}

figure {
    margin: 0;
}

table {
    border-collapse: collapse;
}

th, td {
    padding: rem(10);
    font-size: rem(16);
}

th {
  background-color: $grey-f4f4f4;
}

img {
    max-width: 100%;
    height: auto;
}
