.contact-info {
    background-color: $blue-eceef4;
    border-top: 2px solid $blue-314a8f;
    margin-left: auto;
    margin-right: auto;
    max-width: 850px;
    padding: 45px;

    color: $grey-595956;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
}

.contact-info__cta {
    margin-bottom: 40px;
}

.contact-info__description {

}

.contact-info__phone,
.contact-info__email {
    display: inline-block;
    color: $blue-314a8f;
    font-size: 22px;
    font-weight: 700;
    line-height: 34px;
		word-wrap: break-word;

    @media (max-width: $bp-max-medium){
        display: block;
    }
}

.js-email-text {
	visibility: hidden;
}
