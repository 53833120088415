$search-results__sort-arrow-size: 24;

.search-results {

    &.is-refreshing {
        .search-results__item-wrapper * {
            color: $base-border-color !important;
        }
    }
}

.search__results-mount {
    margin-left: 70px;

    @media(max-width: $bp-max-medium) {
        margin-left: 0;
    }
}

.search-results__item-wrapper {
    position: relative;
}

.search-results__items {
    min-height: rem(350);
    margin-bottom: rem(30);
}

.search-results__status-and-sort {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(30);

    border-bottom: 2px solid $base-border-color;
}


.search-results__sort-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    flex-wrap: wrap;

    ul {
        display: inline-flex;
        list-style: none;
        margin: 0;
    }

    li {
        margin: 0 0 0 rem(25);
    }

		@media(max-width: $bp-max-medium) {
        margin-left: 0;
    }

    @media(max-width: em(415)) {
        & > span {
            display: block;
            padding-bottom: rem(12);
        }
    }

    @media(max-width: em(380)) {

        ul {
            display: block;
        }

        li {
            @include clearfix;
            clear: both;
            width: 100%;
            margin-bottom: rem(12);
        }
    }
}

.search-results__sort-by {
    font-size: 16px;
    color: $grey-353531;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
}

.search-results__sorter {
    cursor: pointer;

    &.is-active {
        font-weight: bold;
    }

}

.search-results__sort-label {
    float: left;
    font-size: rem(16);

}

.search-results__sort-dir {
    display: inline-block;
    position: relative;
    top: rem($search-results__sort-arrow-size / 4);

    button,
    svg {
        width: rem($search-results__sort-arrow-size);
        height: rem($search-results__sort-arrow-size);
    }

    button {
        display: block;
        text-align: center;
        background: transparent;
        border: 0;
        padding: 0;
        margin-top: rem(-(32 / 2));
        padding-top: rem(3);
        color: $grey-a3a3a1;

        &.is-selected {
            color: $blue-314a8f;
        }
    }

    svg {
        fill: currentColor;
    }
}

.search-results__status {
    margin-bottom: rem(30);

    color: $grey-353531;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;

    & > span {
        padding-bottom: rem(16);
    }

		@media(max-width: $bp-max-medium) {
        width: 100%;
    }
}

.serach-results__facet-pills {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
}

.search-results__facet-pill {
    display: inline-flex;
    align-items: middle;

    margin: 5px;
    padding: 7px 10px;
    background-color: $blue-d9deea;
    line-height: 18px;

    // label
    span {
        display: inline-block;
        color: $blue-314a8f;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }

    // X button
    button {
        display: inline-flex;
        align-items: center;
        margin-left: 8px;
        padding: 0;
        border: 0;
        background: transparent;
        cursor: pointer;
        line-height: 18px;

        .icon {
            fill: $blue-314a8f;
            width: 16px;
            height: 16px;
        }
    }
}



.search-results__load-more {
    text-align: center;
    transition: all 0.2s;
    .btn {
        font-size: rem(18);
        padding: rem(12) rem(24);
    }

    .search-results__spinner {
        position: static;
        display: inline;

        img {
            width: rem(24);
            height: rem(24);
            vertical-align: middle;
            margin-left: rem(14);
        }
    }
}

.search-results__pagination {
    text-align: center;
}

.search-results__pages {
    display: inline-block;

    .btn {
        padding: rem(10) rem(15);
    }
}

.search-results__filter-open {
    display: none;
    margin-bottom: rem(16);
    padding: rem(8) rem(12);

    .icon {
        color: $white;
        margin-left: rem(6);
    }

    @media(max-width: $bp-max-medium) {
        display: block;
    }
}

.search-results__spinner {
    position: absolute;
    top: rem(100);
    left: calc(50%  - 88px); // subtract half width of spinner icon
    text-align: center;

    img {
        width: rem(80);
        height: rem(80);
    }
}

.gs-title {
    color: #314a8f !important;
    font-size: 24px !important;
    text-decoration: none !important;
}

.gs-title b {
    color: #314a8f !important;
    font-size: 24px !important;
    text-decoration: none !important;
}

.search-gcse-results {
    max-width: 53.125rem;
    margin: auto;
}

.gs-visibleUrl {
    display: none !important;
}

.gsc-table-result {
    display: -ms-flexbox;
    display: flex;
}

@media screen and (max-width: 600px) {
    .gsc-table-result {
        flex-direction: column;
    }
}

.gsc-thumbnail {
    width: 120px !important;
}

img.gs-image {
    max-width: 100px !important;
}

.gs-snippet {
    color: #595956;
    font-size: 18px;
}

.search-gcse-results .search-results__status {
    margin: 0;
}