.expertise {
}

.expertise__btns {
    margin-top: 60px;

    .btn {
        margin: 0 20px;
    }
}

.expertise__title {
    margin-bottom: rem(32);
    text-align: center;
}
