//Boston Indicators Override

.site-bostonindicators {
  * {
    outline-color: $teal-75b9b9 !important;
  }

  .condensed-header {
    box-shadow: 3px 0 14px 2px rgba(0, 0, 0, 0.4);
  }

  .site-logo {
    img {
      min-width: 200px;
    }

    @media (max-width: $bp-max-medium) {
      max-width: 180px;
    }
  }

  .primary-nav__menu-wrap {
    justify-content: flex-end;
  }

  .primary-nav__menu {
    margin-left: -10px;

    a,
    span {
      padding: rem(15) rem(10) rem(23);
      font-size: rem(18) !important;
    }
  }

  .primary-nav__subitem {
    a {
      font-size: rem(18) !important;
    }
  }

  .search-box__input {
    svg {
      fill: $teal-75b9b9 !important;

      &:hover,
      .is-always-open &,
      .search-box.is-open & {
        fill: $teal-75b9b9 !important;
      }
    }
  }

  .primary-nav__toggle {
    @media(max-width: $bp-max-small) {
      padding-left: 0;
    }
  }

  .search-box {
    // $padding-top:  rem(15);
    @media(min-width: 1291px) {
      top: 31px;
    }
    @media(max-width: $bp-max-medium) {
      top: 90px;
    }
  }
  //weird Edge alignment fix for the magnifying glass
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .search-box {
      @media(min-width: 1000px) {
        top: 30px;
      }
      @media(min-width: $bp-max-medium ) and (max-width: 999px) {
        top: 17px;
      }
    }
  }

  .feature-banner__eyebrow {
    color: $teal-b7eceb !important; // override color pairing
  }

  .utility-nav {
    margin-top: 20px;
    @media(max-width: $bp-max-large) {
      margin-top: 0;
    }

    .utility-nav__item {
      &--highlight {
        border: 2px solid rgba($teal-75b9b9, 0.7);
      }

      &:hover {
        color: $teal-b7eceb;
      }
    }
  }

  .is-always-open .search-box__input input,
  .search-box.is-open .search-box__input input {
    @media(max-width: $bp-max-small) {
      padding: rem(10) rem(8) rem(8) rem(36) !important;
    }
  }

  .condensed-header__top {
    @media(max-width:$bp-max-medium) {
      padding-top: 10px;
    }
  }

  .condensed-header__top-left {
    .primary-nav {
      margin-right: 40px;
      display: block;
      @media(max-width: $bp-max-medium) {
        margin-right: 0;
      }
    }
    @media(max-width: $bp-max-small) {
      display: flex;
      width: 100%;
      padding-right: 0;
    }
  }

  input {
    .is-always-open &,
    .search-box.is-open & {
      padding: 10px 0 10px 38px;
    }
  }

  .search-box__input {
    svg {
      &:hover,
      .is-always-open &,
      .search-box.is-open & {
        fill: $teal-75b9b9;
      }
      @media (min-width: $bp-max-medium) {
        top: -3px;
      }
    }
  }

  .search-box__close {
    &:hover {
      fill: $teal-75b9b9;
    }
  }
}
