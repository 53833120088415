.footer-nav {
	display: flex;
	flex-shrink: 0;
	flex-wrap: wrap;

	a {
			&:hover {
				color: rgba($white,.7);
			}
		}

	@media(max-width: $bp-max-small) {
		display: block;
	}
}

.footer-nav__section {
	margin-right: rem(32);
	margin-bottom: rem(32);
	min-width: rem(240);

	@media(max-width: $bp-max-small) {
		margin-bottom: 0;
	}
}

.footer-nav__col-wrapper {
	display: flex;

	@media(max-width: $bp-max-small) {
		display: block;
	}
}

.footer-nav__col {
	@include zero-list;
	margin-right: rem(24);

	li {
		margin-bottom: 24px;
		font-family: $gibson;
	}

	.footer-nav__col-item-alt {
		background-color: rgba($white,.2);
		border-radius: 6px;
		padding: 5px 10px;
	}

	@media(max-width: $bp-max-small) {
		display: block;
		width: 100%;
	}
}

.footer-nav__heading {
	width: 100%;
    color: inherit;
}
