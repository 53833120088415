﻿.agenda-list {
	@include component-whitespace;


}

.agenda-list__heading {
	text-align: center;
	margin-bottom: rem(47);
}

.agenda-list__table {
	table-layout: fixed;
	margin: 0 auto;
	border: 0;

	th, td {
		border: 0;
		padding: rem(20) rem(40);
		text-align: left;
		
		&.agenda-list__edit {
			padding: rem(10) rem(20);
			text-align: center;
		}
	}
}

.agenda-list__col-heads {
	background-color: $grey-f4f4f4;
	border: 0;
	border-bottom: 2px solid $grey-d1d1d0;

	th {
		font-family: $gibson;
		font-weight: 600;
		font-size: 1.5rem;
		line-height: 1.25;

	}

	@media(max-width: $bp-max-medium) {
		display: none;
	}

}

.agenda-list__row {
	border: 0;
	border-top: 1px solid $grey-d1d1d0;

	&:first-child {
		border-top: 0;
	}

	&.is-hidden {
		display: none;
	}

	td {
		font-family: $stevie;
		font-size: 1.25rem;
	}


	@media (max-width: $bp-max-medium) {
		padding: rem(20) 0;
		background-image: linear-gradient(to right, #f4f4f4 0%, #f4f4f4 37%, #ffffff 37%, #ffffff 100%);

		&, td {
			font-size: rem(14);
			display: block;
			width: 100%;
		}

		td {
			@include clearfix;
			padding-top: rem(10);
			padding-bottom: rem(10);

			&:first-child {
				padding-top: 0;
			}

			span, time {
				float: right;
				width: 65%;
				padding-left: rem(20);
			}
		}

		td:last-child {
			padding-bottom: 0;
		}

		td::before {
			content: attr(data-label);
			float: left;
			text-align: right;
			width: 35%;
			height: 100%;
			font-weight: 600;
			padding-right: rem(20);
		}
	}

	@media (max-width: $bp-max-small) {
		td {
			&::before {
				font-size: rem(16);
			}
		}
	}
}

.agenda-list__more {
	text-align: center;
}

// 50-50 containers share display with mobile
.container--50-50 {
	.agenda-list__col-heads {
		display: none;

	}

	.agenda-list__row {
		padding: rem(20) 0;
		background-image: linear-gradient(to right, #f4f4f4 0%, #f4f4f4 37%, #ffffff 37%, #ffffff 100%);

		&, td {
			font-size: rem(14);
			display: block;
			width: 100%;

			&.is-hidden {
				display: none;
			}
		}

		td {
			@include clearfix;
			padding-top: rem(10);
			padding-bottom: rem(10);

			&:first-child {
				padding-top: 0;
			}

			span, time {
				float: right;
				width: 65%;
				padding-left: rem(20);
				font-size: 16px;
			}
		}

		td:last-child {
			padding-bottom: 0;
		}

		td::before {
			content: attr(data-label);
			float: left;
			text-align: right;
			width: 35%;
			height: 100%;
			font-weight: 600;
			font-size: 16px;
			padding-right: rem(20);
		}

	}
}