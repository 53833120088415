$page-banner__caption-offset: 30;
$bp-event-banner-small: em(600);

.page-banner-slider {
	.page-banner.has-height {
		height: 500px;
		display: flex !important;
		width: 100% !important;
		.hero__container {
			flex: 1;
			display: flex;
			margin: 0;
			max-width: 100%;
			.hero__caption {
				flex: 1;
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-top: 120px;
				padding-bottom: 30px;
				min-height: auto;
				.page-banner__title {
					max-width: 800px;
					text-align: center;
				}
				.btn.btn--secondary {
					background: $primary-btn-color;
					&:hover {
						color: $purple-663477;
					}
				}
			}
		}
	}

	.slick-prev,
	.slick-next {
		z-index: 5;
	}

	.slick-prev {
		left: 25px;
	}

	.slick-next {
		right: 25px;
	}
	.slick-prev:before, .slick-next:before {
		font-size: 32px;
	}
}

.page-banner {
	overflow:hidden;
	@include component-whitespace();
	position: relative;
	color: $white;
}

.page-banner__figure {
	margin: 0;

	img,
	video {
		width: 100%;
		display: block;
		margin: 0 auto;
		object-fit: cover;
	}
}

.has-height .page-banner__figure {
	width: 100%;
	height: 100%;

	picture {
		height: 100%;
	}

	img {
		height: 100%;
	}

	video {
		height: 100%;
	}


	@media(max-width: $bp-max-medium) {
		position: absolute;
			top: 50%;
			left: 50%;
		transform: translate(-50%, -50%);
	}
}

.page-banner__caption {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	max-width: $max-width;
	width: 100%;

	@media(max-width: $bp-max-large) {
		padding-left: rem($page-banner__caption-offset);
	}

	@media(max-width: $bp-max-medium) {
		padding: rem($page-banner__caption-offset);
		position: relative;
			left: 0;
			top: 0;
		transform: translate(0, 0);
	}
}

.page-banner__caption-inner {
	color: $white;
}

.page-banner__eyebrow {
	font-family: $merriweather;
	text-transform: uppercase;
	font-size: 1.125rem;
	margin-bottom: 0;
}

.page-banner__title {
	font-size: 3.75rem;
	margin-bottom: 0;
	color: inherit;

	@media(max-width: $bp-max-medium) {
		margin-bottom: 1rem;
	}

	@media(max-width: $bp-max-small) {
		font-size: 2.5rem;
	}
}

.page-banner__subtitle {
	font-size: 1.5rem;
	font-family: $merriweather;
	margin-bottom: 0;
	color: inherit;

	@media(max-width: $bp-max-medium) {
		font-size: 1.5rem;
	}

	@media(max-width: $bp-max-small) {
		font-size: 1.2rem;
	}
}

.page-banner__description {
	font-size: 20px;
	margin-bottom: 0;

	@media(max-width: $bp-max-medium) {
		font-size: 18px;
	}
}

.page-banner__action {
	display: inline-block;
	color: $white;
	transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1), color 0.28s cubic-bezier(0.4, 0, 0.2, 1); // Fast Out, Slow In
	background-color: darken($white, 10);
	text-decoration: none;
	padding: rem(10) rem(20);

	&:hover {
		background-color: darken($white, 45);
		color: $white;
	}
}

.container--100 {
	.page-banner__caption-inner {
		left: rem($page-banner__caption-offset);
	}
}

.page-banner--event {
	margin-bottom: 0;
	overflow: hidden;



	.page-banner__caption-inner {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		max-width: 850px;
		opacity: 0.85;
		margin: auto;
		padding: 50px;
		position: relative;
		text-align: center;
		width: 100%;

		@media(max-width: $bp-max-medium) {
			align-content: center;
			border-radius: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			top: 0;
			height: 100%;
		}
	}

	.page-banner__caption {
		margin: auto;
		margin-top: rem(80);
		position: relative;
			bottom: 0;
			top: 0;
			left: 0;
		transform: translateX(0);

		@media(max-width: $bp-max-medium) {
			padding: rem($page-banner__caption-offset);
		}
	}

	.page-banner__eyebrow {
		font-family: $gibson;
		display: inline-block;
		font-size: 17px;
		opacity: 0.5;
		vertical-align: middle;
	}

	.page-banner__figure {
		min-height: 100%;
		width: 100%;
		position: absolute;
			top: 50%;
			left: 50%;
		transform: translate(-50%, -50%);
	}

	.page-banner__content-type {
		margin-bottom: 30px;

		@media(max-width: $bp-max-medium) {
			margin-bottom: 10px;
		}
	}

	.page-banner__eyebrow-icon {
		margin-right: 10px;
		max-width: 35px;
		opacity: 0.5;
		vertical-align: middle;
	}

	.page-banner__title {
		font-size: 50px;
		line-height: 56px;
		margin-bottom: 15px;

		@media(max-width: $bp-max-medium) {
			font-size: 30px;
			line-height: 34px;
		}
	}

	.page-banner__subtitle {
		font-size: 24px;
		line-height: 36px;

		@media(max-width: $bp-max-medium) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	.breadcrumbs {
		color: $grey-595956;
		max-width: 1290px;
		padding: rem(12);
		position: relative;
		z-index: 1;
		width: 100%;

		@media(max-width: $bp-max-large) {
			margin-left: 1.875rem;
			margin-right: 1.875rem;
		}

		@media(max-width: $bp-max-medium) {
			display: none;
		}
	}

	picture {
		// remove extra spacing below image
		display: block;
		font-size: 0;
	}
}
