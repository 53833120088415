.card {
    background-color: rgba(255, 255, 255, 0.8);

    > * {
        width: 100%;
    }
}

.card--purple {
    color: white;
    background-color: rgba(102, 52, 119, 0.8);
}

.card--blue {
    color: white;
    background-color: rgba(49, 74, 143, 0.8);
}

.card--video {
   background: url(/assets/img/tbf/homepage-videobg.png) $blue-314a8f;
   background-size: cover;
}

.card--centered {
    display: flex;
    align-items: center;
    justify-content: center;
}
