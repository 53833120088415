.partnerships {
    background-color: $grey-f4f4f4;
    padding: 80px 0;
}


.partnership.media-object {


    p {
        font-size: 16px;
    }

    .media-object__img {
        margin-right: 40px;

        img {
            max-width: 100%;
        }
    }

    .partnerships & {
        padding: 50px 0 64px;


        & + .partnership {
            border-top: 1px solid $grey-d1d1d0;
        }
    }


    @media (max-width: $bp-max-medium){
        flex-direction: column;

        .media-object__img {
            margin: 0 0 20px 0;
            text-align: center;
        }
    }

}

.partnerships__blurb {
    max-width: 830px;
    margin: 60px auto 90px;

    color: $grey-353531;
    font-family: $merriweather;
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
}


.partnership__list {
    font-size: 16px;
}

.partnership__list-label {
    margin-bottom: 14px;
    color: #353531;
    font-family: $gibson;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}
