// class-helpers.scss
// Define class helpers here
// Inspired from Helper Classes from https://html5boilerplate.com/

// color variables
$base-label-color: $gold-a58328 !default;
$base-label-bg: rgba($white, 0.75) !default;
$base-label-border: $base-border-color !default;

// hide from screenreaders and browsers: h5bp.com/u
.u-hidden {
    display: none !important;
    visibility: hidden;
}

// visually hidden, but still available for screenreaders: h5bp.com/v
.u-visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
.u-visuallyhidden.focusable:active,
.u-visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

// hide visually and from screenreaders, but maintain layout
.u-invisible {
    visibility: hidden;
}

// Clearfix: contain floats
//
// For modern browsers
//    1. The space content is one way to avoid an Opera bug when the `contenteditable` attribute is included anywhere else in
//       the document. Otherwise it causes space to appear at the top and bottom of elements that receive the `clearfix` class.
//    2. The use of `table` rather than `block` is only necessary if using `:before` to contain the top-margins of child elements.
.u-clearfix:before,
.u-clearfix:after {
    content: " "; // 1
    display: table; // 2
}

.u-clearfix:after {
    clear: both;
}

.u-clearfix {
    *zoom: 1;
}

// wireframe labels
// NOTE: for wireframing use only.
.u-wire-label {
    @include zero;
    display: inline-block;
    padding: rem(10);
    font-size: rem(13);
    text-transform: uppercase;
    color: $base-label-color;
    background: $base-label-bg;
    border: 1px solid $base-label-border;
}

.u-color-pair {
    *:not(.u-color-pair) {
        color: inherit !important;
    }
}

.u-rounded-image {
	border-radius: 50%;
}

.u-apply-border {
	border-bottom: 1px solid rgba(#a3a3a1, 0.5);
    padding-bottom: 25px;

    &--nopad {
        padding-bottom: 0;
    }
}
