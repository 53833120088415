$secondary-nav-small-bp: rem(700);

.container--link-group {
    // box-shadow: 0 7px 8px -5px rgba(1, 1, 1, 0.15);
    position: relative;
    z-index: 1;
}

.link-group {
    display: flex;
    justify-content: center;

    max-width: $max-width;
    margin: auto;

    border-top: 1px solid rgba(1, 1, 1, 0.15);

    @media(max-width: $secondary-nav-small-bp) {
        flex-wrap: wrap;
    }
}

.link-group__link {
    padding: 20px 30px;
    color: $grey-353531;
    font-size: 22px;
    font-weight: 600;
    font-family: $gibson;

    &:hover {
      color: rgba($grey-353531,.7);
    }

    @media(max-width: $secondary-nav-small-bp) {
        flex-basis: 50%;
    }
}
