.story-mosaic {
    padding-top: 110px;
    padding-bottom: 110px;

    background-size: cover;

}

.story-mosaic__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    margin: -15px;

    .container--bleed & {
      max-width: 1290px;
      margin: 0 auto;

      @media(max-width: $bp-max-large) {
        margin-left: rem(30);
        margin-right: rem(30);
      }
    }

    @media(max-width: $bp-max-large) {
        margin-left: 0;
        margin-right: 0;
    }
}

.story-mosaic__small {
    width: 42%;

    .video-promo {
      text-align: center;

      h6 {
        color: $white;
        font-size: rem(20);
        line-height: 1.2;
        letter-spacing: .04em;
        margin-top:rem(20);

        a {
          color: $white;

          &:hover {
            color: rgba($white, .7);
          }
        }
      }
    }
}

.story-mosaic__large {
    width: 58%;

    .statement {
      text-align: center;
      color: $grey-6c6c69;
      padding: 30px;

      p {
        font-family: $gibson;
        font-weight: 300;
        margin-bottom: 0;
        margin-top: rem(10);
        line-height: 1.5;
        font-size: rem(30);

        @media(max-width: $bp-max-medium) {
          font-size: rem(25);
        }

        span {
          font-family: $merriweather;
          font-weight: 700;
          font-size: rem(38);

          @media(max-width: $bp-max-medium) {
            font-size: rem(30);
          }
        }
      }
    }
}

.story-mosaic__small,
.story-mosaic__large {
    display: flex;

    flex-grow: 0;
    flex-shrink: 0;
    padding: 15px;
    box-sizing: border-box;

    // fill the entire width
    > * {
        flex-grow: 1;
        box-sizing: border-box;
        max-width: 100%;
    }


    @media(max-width: $bp-max-medium) {
        width: 100%;
        padding: 15px 0;
    }
}
