$copyright-symbol-size: rem(14) !default;
$copyright-text-size: rem(14) !default;

.copyright {
    @include zero;
    display: inline-block;
    font-size: $copyright-text-size;
    line-height: $copyright-symbol-size;

    &__symbol {
        display: inline-block;
        font-size: $copyright-symbol-size;
        vertical-align: top;
    }

    a {
      &:hover {
        color: rgba($white,.7);
      }
    }
}
